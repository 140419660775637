<template>
  <Dialog
    :modal="true"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '50vw' }"
  >
    <template #header>
      <h5>{{ t('message.dashboard.addNewDashboard') }}</h5>
    </template>
    <form @submit.prevent="handleSubmit(!validator.$invalid)">
    <div class="p-d-flex p-flex-column gap-4">

      <div class="p-grid p-ai-center">
        <div class="p-col-4 mke-p-0">
            <span class="p-d-block">Titel</span>
            <!-- <span class="p-d-block mx-fg-text-high mke-mt-1 font-size-s">Subline</span>   -->
        </div>
        <div class="p-col-8 mke-p-0">
          <InputText class="block dashboard-title" type="text" v-model="dialog.name"  :class="{'p-invalid':validator.name.$invalid}"/>
        </div>
      </div>


    </div>

      <Divider layout="horizontal" />
      <Button  :label="t('message.dashboard.exit')"
               icon="mdi mdi-close"
               id="close-dashboard"
               class="p-mt-2 p-mr-2 p-button-transparent"
               @click="addDashboardDialogVisible = false">
      </Button>
      <Button
        :label="t('message.dashboard.createDashboard')"
        id="create-dashboard"
        type="submit"
        icon="mdi mdi-check"
        class="p-mt-2">
      </Button>
    </form>
  </Dialog>
</template>

<script>
import {computed, ref} from "vue";
import { useStore } from "vuex";
import {required } from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const store = useStore();
    const rules = {
      name: { required },
    };
    const dialog = ref({name: ''})
    const validator = useVuelidate(rules, dialog);
    const { t } = useI18n({ useScope: 'global' })
    const handleSubmit = (isFormValid) => {
      if (isFormValid) {
        const payload = {}
        store.commit("dashboard/set_add_dashboard_dialog_visible", false)
        Object.assign(payload, dialog.value)
        store.dispatch("dashboard/createDashboard", payload);
        dialog.value = {name: ''}
      }
    }
    const addDashboardDialogVisible = computed({
      get: () => {
        return store.state.dashboard.add_dashboard_dialog_visible;
      },
      set: (value) =>
        store.commit("dashboard/set_add_dashboard_dialog_visible", value),
    });

    // const addNewDashboard = () => {
    //   store.commit("dashboard/set_add_dashboard_dialog_visible", false),
    //   store.dispatch("dashboard/createDashboard");
    //   // TO DO: Change view to the newly created dashboard
    // };

    return { addDashboardDialogVisible, handleSubmit, validator, dialog, t};
  },
};
</script>
