<template>
  <Dialog id="pos-formbuilder-combo-edit-dialog"
          :modal="true"
          :draggable="false"
          :closable="true"
          :visible="true"
          :dismissableMask="true"
          :data-selection-method="combo.combo_selection_method"
          :data-price-method="combo.combo_price_method"
          :data-main-price="combo.price"
          :data-child-price="combo.children_price"
          :data-combo-price="combo.combo_price"
          :data-product-price="combo.product_price"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}"
           :style="{width: '50vw'}"
          >
    <template #header>
      <h3 class="p-d-flex p-jc-between p-align-center w-100">{{combo.name}}</h3>
    </template>

    <TabView>
      <TabPanel :header="t('message.masterdata.general')">
        <mke-form-builder-input-text
            id="master-data-combo-edit-title"
            class="mke-m-2"
            :label="t('message.masterdata.combo.title.label')"
            :placeholder="t('message.masterdata.combo.title.placeholder')"
            v-model="combo.title" />

        <div class="p-grid p-ai-center mke-m-2" v-if="combo.entries">
          <div class="p-col-4 mke-p-0">{{t('message.masterdata.combo.min_max.label')}}</div>
          <div class="p-col-4 mke-p-0 p-d-flex p-justify-between p-align-center">
            <InputNumber
                id="master-data-combo-edit-min"
                v-model="combo.combo_min"
                :placeholder="t('message.masterdata.combo.min.placeholder')"
                mode="decimal"
                class="p-d-block"
                style="flex:1"
            />
            <span>&nbsp;/&nbsp;</span>
            <InputNumber
                id="master-data-combo-edit-max"
                v-model="combo.combo_max"
                :placeholder="t('message.masterdata.combo.max.placeholder')"
                mode="decimal"
                class="p-d-block"

                style="flex:1"
            />
          </div>
        </div>

        <div class="p-grid p-ai-center mke-m-2">
          <div class="p-col-4 mke-p-0">{{t('message.masterdata.combo.price.label')}}</div>
          <div class="p-col-8 mke-p-0 p-d-flex p-align-center">
            <InputNumber
                id="master-data-combo-edit-price"
                v-model="combo.combo_price"
                :placeholder="t('message.masterdata.combo.price.placeholder')"
                mode="decimal"
                min-fraction-digits="0"
                max-fraction-digits="2"
                class="p-d-block"
            />
            <span class="p-d-inline-block mke-ml-2" v-if="combo.product_price !== null">
              <small>
                {{t('message.masterdata.combo.price.product')}} {{n(combo.product_price, 'currency')}}
              </small>
            </span>
          </div>
        </div>

        <mke-form-builder-input-switch
            id="master-data-combo-edit-default"
            class="mke-m-2"
            :label="t('message.masterdata.combo.default.label')"
            v-model="combo.is_default"/>
      </TabPanel>
      <TabPanel :header="t('message.masterdata.combo.selection-method.label')" v-if="combo.entries">
          <div class="mke-m-4">
            <div class="field-radiobutton p-d-flex mke-mb-2" style="gap: 0.5em"
                 v-for="selectionMethod in selectionMethods" :key="selectionMethod">
              <RadioButton
                  :id="'combo_selection_method_' + selectionMethod"
                  name="combo_selection_method"
                  :value="selectionMethod"
                  v-model="combo.combo_selection_method"/>
              <label :for="'combo_selection_method_' + selectionMethod">
                {{t('message.masterdata.combo.selection-method.' + selectionMethod + '.label')}}
                <br/>
                <small>
                  <em>
                    {{t('message.masterdata.combo.selection-method.' + selectionMethod + '.description')}}
                  </em>
                </small>
              </label>
            </div>
          </div>
      </TabPanel>
      <TabPanel :header="t('message.masterdata.combo.price-method.label')" v-if="combo.entries">
        <div class="mke-m-4">
          <div class="field-radiobutton p-d-flex mke-mb-2" style="gap: 0.5em"
               v-for="priceMethod in priceMethods" :key="priceMethod">
            <RadioButton
                :id="'combo_price_method_' + priceMethod"
                name="combo_price_method"
                :value="priceMethod"
                v-model="combo.combo_price_method"/>
            <label :for="'combo_price_method_' + priceMethod">
              {{t('message.masterdata.combo.price-method.' + priceMethod + '.label')}}
              <br/>
              <small>
                <em>
                  {{t('message.masterdata.combo.price-method.' + priceMethod + '.description')}}
                </em>
              </small>
            </label>
          </div>
        </div>
      </TabPanel>
    </TabView>

    <template #footer>
      <mke-button id="button-combo-cancel" :label="t('message.masterdata.button.cancel')" icon="pi pi-times" class="p-button-transparent" @click="closeDialog" />
      <mke-button id="button-combo-commit" :label="t('message.masterdata.button.accept')" icon="pi pi-check" class="p-button-primary" @click="commitCombo" />
    </template>
  </Dialog>
</template>

<script>
import {useStore} from "vuex";
import {ref} from "vue";
import deepClone from "@/utilities";
import {useI18n} from "vue-i18n";

export default {
  name: "PosFormBuilderComboEditDialog",
  props: ["comboToEdit"],
  emits: ["onComboComitted", "onComboCanceled"],
  setup(props, context){
    const store = useStore();

    const { t, n } = useI18n({ useScope: 'global' })

    const combo = ref( deepClone(props.comboToEdit) );

    console.log( "In edit-dialog: ", combo);

    const selectionMethods = [ "combinable", "not_combinable", "additional", "remove", "each" ]

    const priceMethods = ["main", "children", "max", "min", "count", "free", "add"]

    const closeDialog = () => {
      store.commit("general/addToast", {
        detail: t('message.masterdata.toast.cancel'),
        life: 3000,
        severity: "info",
        summary: "Info"
      } );
      context.emit("comboCanceled");
    }

    const commitCombo = () => {
      context.emit("comboComitted", combo);
    }

    return {
      t, n, combo, closeDialog, commitCombo, selectionMethods, priceMethods
    }
  }
}

</script>

<style scoped>

</style>
