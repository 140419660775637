import {defineStore} from "pinia";
import {computed, ref} from "vue";
import deepClone from "@/utilities";

/**
 * Verwaltet das Splitten von Bestellungen auf mehrere Vorgänge.
 */
export const useSplitEntriesStore = defineStore('splitEntries', () => {

    // The model of the splits, especially, holds the informatione, if they're enabled or disabled for splitting
    const splits = ref([]);
    // The current number of splits
    const noOfSplit = computed(() => splits.value.length);
    // The currently selected split
    const currentSplit = ref(0);

    const totalPaymentInfo = ref(undefined);

    // The entries containing also the splitting information
    const splitEntries = ref([]);

    // The origin process of the splits
    const sourceProcess = ref({});
    // The title for the overview-part
    const sourceTitle = ref("");
    // Prefix for the names of teh splits
    const splitPrefix = ref( "" );
    // The entries, which can be currently splitted
    const entriesForSplit = computed( () => splitEntries.value.filter(e => e.price > 0 ));

    function init(name, prefix, process) {
        reset();
        sourceProcess.value = deepClone(process);
        setEntries(sourceProcess.value.process_entries);
        sourceTitle.value = name;
        splitPrefix.value = prefix;
    }

    function reset() {
        splits.value = [];
        currentSplit.value = 0;
        splitEntries.value = [];
        totalPaymentInfo.value = undefined;
    }

    function setEntries(newEntries) {
        const normedEntries = [];
        newEntries.forEach((newEntry) => {
            let entry = deepClone(newEntry);
            if (entry.base_price === 0 && entry.price > 0 && entry.quantity > 0) {
                entry.base_price = entry.price / entry.quantity;
            }

            entry.original_price = entry.price;
            entry.original_quantity = entry.quantity;
            entry.splittedEntries = []
            entry.cssClas=""

            normedEntries.push(entry);
        });
        splitEntries.value = normedEntries;
    }

    function updateEntries(entries) {
        entries.forEach((updatedEntry) => {
            const entry = splitEntries.value.find(e => e.id === updatedEntry.id);
            if( entry ) {
                entry.original_price = updatedEntry.price;
                entry.original_quantity = updatedEntry.quantity;
            }
        });
    }

    const nameOfSplit = (split) => {
        if( split === 0 ) {
            return sourceTitle;
        } else {
            return splitPrefix.value + splits.value[split-1]?.name;
        }
    }

    function splittedEntriesOfSplit(split) {
        return splitEntries.value.filter((entry) => entry.splittedEntries[split-1].quantity > 0);
    }

    function sumOfEntries() {
        return splitEntries.value.reduce(
            (previousValue, entry) => previousValue + roundToTwo(entry.price),
            0 );
    }

    function sumOfSplittedEntries(split) {
        if( split === 0 ) {
            return sumOfEntries();
        } else {
            return splitEntries.value.reduce(
                (previousValue, entry) => previousValue + roundToTwo(entry.splittedEntries[split-1].price),
                0 );
        }
    }

    function addSplit(process) {
        const newSplit = {index:noOfSplit.value+1, name: process.name, enabled: true, process: process};
        splits.value.push(newSplit);

        splitEntries.value.forEach((entry) => {
            let splittedEntry = {index: newSplit.index, quantity: 0, price: 0, color: "default", enabled:true};

            entry.splittedEntries.push(splittedEntry);
        });

        currentSplit.value = noOfSplit.value;
    }

    function applyPaymentToSplit(split, paymentInfo) {
        if( split > 0 ) {
            splits.value[split-1].paymentInfo = paymentInfo;
        } else {
            totalPaymentInfo.value = paymentInfo;
        }

    }

    const currentPayment = () => {
        if( currentSplit.value > 0 && splits.value[currentSplit.value-1] && splits.value[currentSplit.value-1].paymentInfo ) {
            return splits.value[currentSplit.value - 1].paymentInfo;
        } else if( currentSplit.value === 0 ) {
            return totalPaymentInfo.value;
        } else {
            return undefined;
        }
    }

    function distributeAll() {
        splitEntries.value.forEach((entry) => {
            addProductDistributed(entry);
        });
    }

    function removeSplit(split) {
        if( split > 0 ) {
            splits.value.splice(split-1, 1);
            splitEntries.value.forEach((entry) => {
                entry.quantity += entry.splittedEntries[split - 1].quantity;
                if( entry.quantity > entry.original_quantity ) {
                    entry.quantity = entry.original_quantity;
                }
                entry.price += entry.splittedEntries[split - 1].price;
                entry.splittedEntries.splice(split - 1, 1);
                entry.splittedEntries.forEach((splitEntry, index) => {
                    splitEntry.index = index+1;
                });
                currentSplit.value = noOfSplit.value;
            });
        }
    }

    function addProductToSplit(entry, split, noOfEntry) {
        if( noOfEntry > entry.quantity ) {
            noOfEntry = entry.quantity;
        }

        if( noOfEntry > 0 && split > 0 ) {
            if( entry.quantity - noOfEntry < 0.00001 ) {
                entry.quantity = 0;
            } else {
                entry.quantity = entry.quantity - noOfEntry;
            }

            entry.splittedEntries[split-1].quantity = entry.splittedEntries[split-1].quantity + noOfEntry;
            if( noOfEntry * entry.base_price <= entry.price ) {
                entry.splittedEntries[split-1].price = entry.splittedEntries[split-1].quantity * entry.base_price;
            } else {
                // Wenn wir einen Campari zu 3,10 € dritteln, dann bleibt 1ct für < 0,01 Campari übrig, den der Kellner verteilen muss.
                // Wenn zu einer Bestellung nun dieser letzte Cent zugefügt werden soll, dem Split von 0,33 Campari aber nun
                // 0,01 Campari hinzugefügt wird, enden wir bei 0,34 Campari und wenn wir aus der 0,34 den Preis berechnen,
                // landen wir bei 1,05 und nicht den eigentlich richtigen 1,04.
                // Daher: übersteigt der berechnete Preis dem Restbetrag, nehmen wir den Restbetrag.
                entry.splittedEntries[split-1].price = roundToTwo(entry.splittedEntries[split-1].price) + roundToTwo(entry.price)
            }

            entry.splittedEntries[split-1].color = "primary"
            entry.price = entry.quantity * entry.base_price;
        } else if( entry.price > 0 && entry.price < 0.1 ) {
            entry.splittedEntries[split-1].price += entry.price;
            entry.price = 0;
            entry.splittedEntries[split-1].color = "primary"
        }

    }

    function removeProductFromSplit(entry, split, noOfEntry) {
        if( split > 0 && entry.splittedEntries[split-1].quantity > 0) {
            if( noOfEntry > entry.splittedEntries[split-1].quantity ) {
                noOfEntry = entry.splittedEntries[split-1].quantity;
            }

            if( entry.splittedEntries[split-1].quantity < noOfEntry ) {
                entry.quantity += entry.splittedEntries[split-1].quantity;
                entry.splittedEntries[split-1].quantity = 0;
            } else {
                entry.quantity = entry.quantity + noOfEntry;
                entry.splittedEntries[split-1].quantity = entry.splittedEntries[split-1].quantity - noOfEntry;
            }
            if( entry.quantity > entry.original_quantity ) {
                entry.quantity = entry.original_quantity;
            }
            entry.price = entry.quantity * entry.base_price;
            entry.splittedEntries[split-1].price = entry.splittedEntries[split-1].quantity * entry.base_price;

            if( entry.splittedEntries[split-1].quantity === 0 ) {
                entry.splittedEntries[split-1].color = "default"
            }
        }
    }

    function takeover(split) {
        if( split > 0 ) {
            splitEntries.value.forEach((entry) => {
                if (entry.quantity > 0) {
                    addProductToSplit(entry, split, entry.quantity);
                }
            });
        }
    }

    function resetSplit(split) {
        if( split > 0 ) {
            splitEntries.value.forEach((entry) => {
                if (entry.splittedEntries[split-1].quantity > 0) {
                    removeProductFromSplit(entry, split, entry.splittedEntries[split-1].quantity);
                }
            });
        }
    }

    function addProductDistributed(entry) {
        const countSplits = splits.value.filter( s => s.enabled ).length
        let quantity = entry.original_quantity / countSplits;

        // Thema Rundungsproblematik: durch das Runden (in der Anzeige) kann es passieren,
        // das die Summe der gesplitteten Einträge grösser als die Endsumme ist.
        // Beispiel: wenn ein Artikel zu 4,70€ gedrittelt wird, dann ergibt sich ein Preis von 1,5666666 pro Split
        // in der Anzeige wird dann 1,57€ angezeigt, was aber einer Gesamtsumme von 4,71€ entsprechen würde.
        // Daher vermindern wir die Quantität, bis dies nicht mehr passiert.
        // Das Ergebnis ist dann 1,56€ pro Split, als Summe 4,68€ und 2cent verbleiben als Rest.
        // Ähnliches kann bei der Menge geschehen, da zum Beispiel 2/3 Getränke 0,666666, ergo 0,67, entsprechen
        let diffCumulatedPrices = 0;
        let diffCumulatedQuantity = 0;
        do {
            diffCumulatedPrices = entry.original_price - countSplits * roundToTwo(entry.base_price * quantity) ;
            diffCumulatedQuantity = entry.original_quantity - countSplits * roundToTwo( quantity) ;

            if( diffCumulatedPrices < 0 || diffCumulatedQuantity < 0) {
                quantity = quantity - 0.00001;
            }

        } while( diffCumulatedPrices < 0 || diffCumulatedQuantity < 0)

        entry.quantity = entry.original_quantity;

        for(let i = 1; i <= noOfSplit.value; i++) {
            if( splits.value[i-1].enabled ) {
                entry.splittedEntries[i-1].quantity = 0;
                entry.splittedEntries[i-1].price = 0;
            }
        }
        for (let i = 1; i <= noOfSplit.value; i++) {
            if( splits.value[i-1].enabled ) {
                addProductToSplit(entry, i, quantity);
            }
        }

        correct_prices_for_rounding(entry);
    }

    function entryDetails(entry) {
        let details = [];
        if( entry.child_entries && entry.child_entries.length > 0 ) {
            details = entry.child_entries.map( c => {
                let detail = c.name;
                let childDetails = entryDetails(c);
                if( childDetails ) {
                    detail += " (" + childDetails + ")";
                }
                return detail;
            })
        }

        return details.join(" / ");
    }

    function correct_prices_for_rounding(entry) {
        const price_of_entries = entry.splittedEntries.reduce( (previousValue, splittedEntry) =>
                previousValue + (splittedEntry.enabled ? roundToTwo(splittedEntry.price) : 0),
            roundToTwo(entry.price) );
        const diff_price = entry.original_price - price_of_entries;

        const quantity_of_entries = entry.splittedEntries.reduce( (previousValue, splittedEntry) =>
                previousValue + (splittedEntry.enabled ? roundToTwo(splittedEntry.quantity) : 0),
            roundToTwo(entry.quantity) );
        const diff_quantity = entry.original_quantity - quantity_of_entries;

        if( Math.abs(diff_price) >= 0.005 ) {
            entry.price += diff_price;
            entry.quantity += diff_quantity;
        } else if( Math.abs(diff_quantity) >= 0.005 && entry.price < 0.001 ) {
            // Den letzten beissen die Hunde, aber nur wenn wir Mengenmässig aufrunden müssen und nicht preismässig
            const last_entry = entry.splittedEntries[entry.splittedEntries.length - 1];
            last_entry.quantity += diff_quantity;
        }
    }


    function roundToTwo(num) {
        const m = Number((Math.abs(num) * 100).toPrecision(15));
        return Math.round(m) / 100 * Math.sign(num);
    }

    function mapSplitEntry(split, entry) {
        const mappedEntry = { entry_id: entry.id, quantity: entry.quantity, price: entry.price};
        if( split > 0 ) {
            mappedEntry.quantity = entry.splittedEntries[split-1].quantity;
            mappedEntry.price = entry.splittedEntries[split-1].price;
        }
        return mappedEntry;
    }

    function getSplitInfo(split) {
        const totalPrice = sumOfSplittedEntries(split);
        const entries = splitEntries.value.map(
            entry => mapSplitEntry(split, entry)
        ).filter(
            entry => entry.quantity != 0
        );

        // Wir haben nur main, wenn nicht gesplittet wurde
        const typeOfSplit = noOfSplit.value > 0 ? "split" : "main";

        const process = split > 0 ? splits.value[split-1].process : sourceProcess;

        return {process: process, entries:entries, price: totalPrice, typeOfSplit: typeOfSplit, split: split}
    }

    function getCurrentSplitInfo() {
        return getSplitInfo(currentSplit.value);
    }

    function getAllSplitInfos() {
        const processes = splits.value.map( (split) => {
            const splitInfo = getSplitInfo(split.index);

            const process = deepClone(split.process);
            process.entries = splitInfo.entries;
            return process
        }).filter(p => p.entries?.length > 0);

        return processes;
    }

    function disableSplit(split) {
        splits.value[split-1].enabled = false;
        splitEntries.value.forEach(e => e.splittedEntries[split-1].enabled = false);
    }

    function isSplitDisabled(split) {
        if( split === 0 || split > splits.value.length) {
            return false;
        } else {
            return !splits.value[split-1].enabled;
        }
    }

    function isSplitEnabled(split) {
        if( split === 0 || split > splits.value.length) {
            return true;
        } else {
            return splits.value[split-1].enabled;
        }
    }

    return {
        splitEntries,
        entriesForSplit,
        sumOfEntries,
        noOfSplit,
        removeSplit,
        addProductToSplit,
        removeProductFromSplit,
        addProductDistributed,
        distributeAll,
        sumOfSplittedEntries,
        splittedEntriesOfSplit,
        takeover,
        resetSplit,
        roundToTwo,
        entryDetails,

        init,
        nameOfSplit,
        addSplit,
        currentSplit,
        sourceProcess,
        sourceTitle,
        splits,
        getCurrentSplitInfo,
        getAllSplitInfos,
        disableSplit,
        isSplitDisabled,
        isSplitEnabled,
        updateEntries,
        applyPaymentToSplit,
        currentPayment
    };
})