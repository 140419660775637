<template>
  <div>
    <h2 v-if="title" class="h5 mke-mb-5">{{title}}</h2>
    <DataTable
      :id="id"
      :value="modelData"
      @row-dblclick="onRowDbClick($event)"
      :lazy="lazy"
      :loading="loading"
      :paginator="true"
      :rowHover="true"
      :rows="rows || 20"
      :totalRecords="totalRecords"
      :rowsPerPageOptions="rowsPerPageOptions"
      responsiveLayout="scroll"
      v-model:selection="selectedDataRef"
      :filters="filters"
      :filterDisplay="filters ? 'row' : 'none'"
      @page="onPage($event)"
      @row-click="onRowClick($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      class="mke-datatable"
    >
      <template #header>
        <div class="p-d-flex p-jc-between p-ai-center">
          <div class="p-d-flex p-ai-center">

            <div class="p-inputgroup">
             <Dropdown
                 class=""  placeholder="message.dashboard.actions"
                :options="actions" optionLabel="name" optionValue="action"
                @change="onActionSelect($event)">
               <template #empty>
                 <span>{{ t('message.admin.noActionFound') }}</span>
               </template>
               <template #value="value">
                 <span v-if="!value.value" >{{ t(value.placeholder) }}</span>
               </template>
             </Dropdown>
            </div>
          </div>
<!--          <span class="p-input-icon-left">-->
<!--            <i class="pi pi-search" />-->
<!--            <InputText :placeholder="placeholder" />-->
<!--          </span>-->
        </div>
      </template>

      <template #empty>
        {{ t('message.dashboard.nodataFound') }}
      </template>

      <!-- Selection -->
      <Column v-if="selectionMode == 'toggle'"
          headerStyle="width: 4rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible"
      >
        <template #header>
          {{ t('message.admin.noYes') }}
        </template>
        <template #body="slotProps">
          <div id="mke-data-table-input-switch" class="p-d-flex ">
            <InputSwitch v-model="toggleRef[slotProps.data.id]"
                         @input="onToggle(slotProps.data)"
                         :trueValue="true"
                         :falseValue="false">
            </InputSwitch>
          </div>
        </template>
      </Column>
      <Column v-else-if="selectionMode == 'multiple'" selectionMode="multiple" headerStyle="width: 3rem"></Column>

      <!-- Data Columns -->
      <Column style="white-space: pre" v-for="col of columns"
              :field="col.field"
              :header="col.label"
              :key="col.field"
              :sortable="true">
        <template #filter="{filterModel,filterCallback}" v-if="col.filter || col.options">
          <InputText v-if="filterModel && col.filter" type="text" v-model="filterModel.value" @keydown.enter="filterCallback" class="p-column-filter" :placeholder="`Search by ${col.label} - ${filterModel.matchMode}`"/>
          <Dropdown v-if="col.options" v-model="filterModel.value" :options="col.options" optionLabel="label" optionValue="id" :filter="true"
                    @change="filterCallback"
                    :showClear="true" />
        </template>
        <template #body="slotProps">
          <div class="text-align-right mke-mr-6" v-if="col.type === 'currency' && slotProps.data[slotProps.field] !== null" >
            {{n(slotProps.data[slotProps.field], 'currency')}}
          </div>
          <div class="text-align-left mke-mr-6" v-else-if="col.type === 'datetime' && slotProps.data[slotProps.field] !== null" >
            {{d(slotProps.data[slotProps.field], 'datetime_long')}}
          </div>
          <div class="p-d-flex p-ai-center" v-else-if="col.type === 'state' && slotProps.data[slotProps.field] !== null" >
            <!-- icon if 'ok' -->
            <i
                v-if="slotProps.data[slotProps.field] == 'Ok'"
                class="mdi mdi-thumb-up mdi-2x mx-fg-success-high p-d-flex p-ai-center mke-mr-2"
            />
            <!-- icon if 'error' -->
            <i
                v-if="slotProps.data[slotProps.field] == 'Error'"
                class="mdi mdi-alert-circle mdi-2x mx-fg-danger-high p-d-flex p-ai-center mke-mr-2"
            />
            <!-- icon if 'warning' -->
            <i
                v-if="slotProps.data[slotProps.field] == 'Warning'"
                class="mdi mdi-information mdi-2x mx-fg-warning-high p-d-flex p-ai-center mke-mr-2"
            />
            <!-- icon if 'offline' -->
            <i
                v-if="slotProps.data[slotProps.field] == 'Offline'"
                class="mdi mdi-lightning-bolt-circle mdi-2x mx-fg-text-high p-d-flex p-ai-center mke-mr-2"
            />
            {{ slotProps.data[slotProps.field] }}
          </div>
          <span v-else-if="col.translation_prefix">
            {{t(col.translation_prefix + slotProps.data[slotProps.field])}}
          </span>
          <span v-else>
            {{slotProps.data[slotProps.field]}}
          </span>
        </template>
      </Column>

      <!-- Edit / Delete Button Columns -->
      <Column
        headerStyle="width: 4rem; text-align: center"
        bodyStyle="text-align: center; overflow: visible"
        class="row-actions"
      >
        <template #body="slotProps">
          <div class="p-d-flex ">
            <mke-button
                v-if="editButton"
                color="primary"
                icon="mdi mdi-pencil-outline"
                class="mke-mr-2"
                fontsize="xl"
                v-tooltip.bottom="'Edit'"
                @click="onEdit(slotProps.data)"
            />
            <mke-button
                v-if="deleteButton"
                color="danger"
                icon="mdi mdi-delete-outline"
                class=""
                fontsize="xl"
                v-tooltip.bottom="'Delete'"
                @click="onShowDeleteDialog(slotProps.data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <Dialog v-model:visible="showDeleteDialog" :style="{width: '50vw'}" :dismissableMask="true" :draggable="false"
            :closable=false :modal=true>
      <template #header>
        <div>
          <h5 class="p-d-inline-block mke-mr-2">{{ t('message.dashboard.confirmDelete') }}</h5>
          <span>{{ t('message.dashboard.deleteMessage') }}</span>
        </div>
      </template>
      <template #footer>
        <Button :label="t('message.dashboard.cancel')" @click="showDeleteDialog = false">
        </Button>
        <Button :label="t('message.dashboard.accept')" @click="onDelete()">
        </Button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from 'vue-i18n'
import {usePrimeVue} from "primevue/config";

export default {
  name: "mkeDataTable",
  props: ["actions", "columns", "deleteButton", "editButton", "id", "title", "loading", "modelData", "modelValue",
    "rows", "rowsPerPageOptions", "selectedData", "selectionMode", "totalRecords", "lazy", "filters"],
  setup(props, context) {
    const rowData = ref(null)
    const selectedDataRef = ref()
    const showDeleteDialog = ref(false)
    const toggleRef = ref(props.selectedData || {});
    const { t, n, d } = useI18n({ useScope: 'global' })
    const placeholder = ref(t("message.dashboard.search"))

    /**
     * Called if an action was selected.
     * Used to emit the selected action.
     */
    function onActionSelect(value) {
      context.emit("onAction", {action: value.value, selectedData: selectedDataRef.value})
    }

    function onDelete() {
      showDeleteDialog.value = false
      context.emit("onDelete", rowData.value)
    }

    function onEdit(data) {
      context.emit("onEdit", data)
    }

    function onShowDeleteDialog(data) {
      rowData.value = data
      showDeleteDialog.value = true
    }

    function onToggle(data) {
      context.emit("update:modelValue", Object.keys(toggleRef.value).filter(x => toggleRef.value[x] == true))
      context.emit("onToggle", data)
    }

    /**
     * Emit on page change
     */
    function onPage(event) {
      context.emit("onPage", event)
    }

    /**
     * Emit on row click
     */
    function onRowClick(event) {
      context.emit("onRowClick", event.data)
    }
    /**
     * Emit on row double click
     */
    function onRowDbClick(event) {
      context.emit("onRowDbClick", event.data)
    }

    /**
     * Emit on sort
     */
    function onSort(event) {
      context.emit("onSort", event)
    }

    function onFilter(event) {
      context.emit("onFilter", event)
    }
    /**
     * primevue locale
     */
    const primevue = usePrimeVue();
    primevue.config.locale.startsWith = t("message.dashboard.primeVue.startsWith")
    primevue.config.locale.contains = t("message.dashboard.primeVue.contains")
    primevue.config.locale.notContains = t("message.dashboard.primeVue.notContains")
    primevue.config.locale.equals = t("message.dashboard.primeVue.equals")
    primevue.config.locale.notEquals = t("message.dashboard.primeVue.notEquals")
    primevue.config.locale.endsWith = t("message.dashboard.primeVue.endsWith")
    primevue.config.locale.noFilter = t("message.dashboard.primeVue.noFilter")

    return {
      onActionSelect,
      onDelete,
      onEdit,
      onPage,
      onRowClick,
      onRowDbClick,
      onShowDeleteDialog,
      onSort,
      onToggle,
      rowData,
      selectedDataRef,
      showDeleteDialog,
      toggleRef,
      placeholder,
      t,
      n,
      d,
      onFilter,
    };
  },
};
</script>

<style scoped></style>
