<template>
  <div :key="componentKey">
    <query-builder ref="queryBuilder" :dataSource="dataSource" :rules="importRules"
                   :cube="cube_name"  @ruleChange="sendValidRules"></query-builder>
  </div>
</template>

<script>
import QueryBuilder from "@/components/dashboard/querybuilder/QueryBuilder";
import {useStore} from "vuex";
import {computed, ref, watch} from "vue";
import deepClone from "../../../../utilities.js";
import moment from 'moment';

moment().format();

export default {
  name: "mkeQueryBuilder",
  props: ["cube_name", "importRules"],
  components: {
    QueryBuilder

  },
  emits: ['changeRules'],
  setup(props, context) {
    const store = useStore();
    const cubes_store = computed(() =>
        store.getters["dashboard/get_cuberules"]()
    );
    store.dispatch('query_builder/set_field', {cubeName: props.cube_name})
    const queryBuilder = ref()

    function sendValidRules() {
      let rules = queryBuilder.value.getValidRules()
      rules.rules.filter(rule => rule.type === "date").map(rule => {
        if (Array.isArray(rule.value)) {
          rule.value = rule.value.map((element) => {
            if (element instanceof Date)
              return moment(element, "DD/MM/YYYY").format("YYYY-MM-DD")
            else
              return element
          })
        } else
          return rule.value = moment(rule.value, "DD/MM/YYYY").format("YYYY-MM-DD")
      })
      context.emit('changeRules', rules)
    }

    const dataSource = ref()
    const componentKey = ref(0)
    dataSource.value = deepClone(cubes_store.value[props.cube_name]);
    watch(props, () => {
      dataSource.value = deepClone(cubes_store.value[props.cube_name]);
      changed_cube()
      if (!(props.cube_name in store.state.query_builder.query_builder_value_options)) {
        store.dispatch('query_builder/set_field', {cubeName: props.cube_name})
      }
    })

    function changed_cube() {
      componentKey.value += 1
    }

    return {dataSource, queryBuilder, sendValidRules, componentKey};
  },

}
</script>
