/**
 * Calculates and validates the state, price, etc of the given combo
 * after selecting or deselecting one (child) entry in the combo.
 *
 * This function is recursively called for all childs of the given combo.
 * Afterwards the price, the constraints and state of the given combo is calculated.
 *
 * @param combo The combo to validate
 * @param selectedEntry The selected entry (could be a child of this combo or the combo itself)
 * @param level The current level of hierarchy
 * @param added Is the selected entry added or removed?
 * @returns {{selected_quantity: (number|*), selected_quantity_of_childs: (number|*), combo_price_method: *, combo_selection_method: *, color: string, display_price: number, child_mode: string, combo_min: *, children_price: (number|*), combo_selected, title, is_default: (boolean|*), valid: (*|boolean), entries: *[], price: *, combo_max: *, combo_price: *, name, plu: *, id, selection_title: (string|string|*), additional_text: string, key}}
 */
function synchCombo(combo, selectedEntry, level, added) {

    let currentCombo = {
        id: combo.id, // the id of the product representing this combo
        key: combo.key, // unique key of the combo considering the hierarchy -
                        // in case the same product is referenced in different parts of the combo
                        // like "Eiskugel" which can be part of "3 Kugeln" and part of "weitere Eiskugel"
        title: combo.title, // the title of the combo - if not set, the name of the combo is used
        name: combo.name, // the name of the product representing this combo
        selection_title: combo.selection_title, // description of what the user needs to select
        combo_min: combo.combo_min, // the minimum amount to select
        combo_max: combo.combo_max, // the maximum amount to select
        combo_selected: combo.selected, // indicates, if this combo (or one of the childs) is selected by the user
        is_default: combo.is_default, // indicates, if the parent is selected, this combo is selected as well
        plu: combo.product?.plu ?? combo.plu, // the plu of the product representing this combo
        selected_quantity: combo.selected_quantity ?? 0, // the current selected amount
        selected_quantity_of_childs: combo.selected_quantity_of_childs ?? 0, // the amount of selected children
        valid: combo.valid, // indicates, if the current selection is valid
        color: 'dark', // how the combo should be represented in the frontend
        combo_selection_method: combo.combo_selection_method, // the selection-method of the combo, one of:
                                                      // combinable, not_combinable, unique, each, remove, additional
        combo_price_method: combo.combo_price_method, // the method for calculating the price, one of:
                                                      // main, children, max, min, add, count, free
        price: combo.price, // the (single) price of the product within this combo
        display_price: 0, // the price which should be displayed for the combo
        additional_text: "", // indicates how the price should be displayed, one of
                             // price - displays the prices as is
                             // price_add - displays the price as an addition to the combo price
                             // no_price_add - displays a constant text "Kein Zusatzpreis"
                                // (used for combos where some childrens have additional prices, but other haven't)
        children_price: combo.children_price, // the total price of the selected children
        combo_price: combo.combo_price, // the total price of the combo
        entries: [], // child entries of the combo (conforms to this structure)
        child_mode: "no_child" // represents how this combo should be displayed (handled by PosProcessComboComponent),
                        // one of:
                        // no_child: this combo has no child and should displayed as a button
                        // no_child_childs: this combos has childs, but none of the children, depending on the level
                        //   this combo will be displayed different:
                        //   in the root level, all childrens will be displayed as buttons
                        //   (so you see all "garstufen" at a glance)
                        //   in the deeper levels, this combo will be displayed as one button and the childs
                        //   are displayed only if, this combo is selected
                        // all_child_childs: all childs of this combo have themselve childs and this combo will be
                        //   displayed with a section for each child in which the child childs are displayed as buttons
                        // childs: some childs but not all have childs, all childrens will be displayed as buttons,
                        //   and for all selected direct childs, a section with the child-childs are rendered.
    }

    // Indicates if at least one child of the combo is selected, it's needed
    // a) to select this combo as well, if one child is selected
    // b) to select default childs, if this combo is selected but no child
    let child_selected = false;

        // First process all child entries
    if( combo.entries && combo.entries.length > 0 ) {
        let all_have_childs = true; // indicates that all childs have childs as well
        let no_have_childs = true; // indicates that no child have childs themselve
        combo.entries.forEach((entry) => {
            entry = synchCombo(entry, selectedEntry, level+1, added);

            if( entry.combo_selected ) {
                child_selected = true;
            }

            if( entry.entries.length > 0 ) {
                // at least one have childs
                no_have_childs = false;
            } else {
                // at least one have no childs
                all_have_childs = false;
            }

            currentCombo.entries.push(entry);
        });

        // Depending on level and if all childs have childs or no childs, we're calculating the child mode for
        // appropriate rendering in the frontend
        if( currentCombo.combo_min === 1 && currentCombo.combo_max === 1 ) {
            currentCombo.child_mode = "childs";
        } else if( no_have_childs ) {
            currentCombo.child_mode = "no_child_childs";
        } else if( all_have_childs ) {
            currentCombo.child_mode = "all_child_childs";
        } else {
            currentCombo.child_mode = "childs";
        }

            // if this combo has only one child, then gather all child entries of this child as children of this combo
            // For example, in case of "Garstufenwahl", the combo Garstufenwahl is replaced by
            // "well done", "medium" and so on
        if( currentCombo.entries.length === 1 && currentCombo.entries[0].entries.length > 0 ) {
            if( currentCombo.combo_min === null ) {
                currentCombo.combo_min = currentCombo.entries[0].combo_min;
            }
            if( currentCombo.combo_max === null ) {
                currentCombo.combo_max = currentCombo.entries[0].combo_max;
            }
            currentCombo.child_mode = currentCombo.entries[0].child_mode;
            currentCombo.entries = currentCombo.entries[0].entries;
        }

        // selection method not_combinable does not allow selecting childs, so there are no childs for the frontend
        if( currentCombo.combo_selection_method === "not_combinable" ) {
            currentCombo.child_mode = "no_child";
        }

        // selection method each and not_combinable selects all childs
        if( currentCombo.combo_selection_method === "each" || currentCombo.combo_selection_method === "not_combinable") {
            currentCombo.combo_min = currentCombo.entries.length
            currentCombo.combo_max = currentCombo.entries.length
            currentCombo.entries.forEach((entry) => {
               entry.is_default = true;
            });
            if( !child_selected && level === 1 ) {
                child_selected = applyDefaults(currentCombo);
            }
        } else if( currentCombo.child_mode === "all_child_childs" && !child_selected && level === 1) {
            child_selected = applyDefaults(currentCombo);
        }

            // In case only one child can be selected, but the waiter has reselected one entry, we have a quantity
            // of more than one, so deselect all children but the current selected
        if( currentCombo.combo_min === 1 && currentCombo.combo_max === 1 && child_selected ) {
            // is the current selected entry a direct child?
            let direct_child_selected = false;
            if( selectedEntry ) {
                direct_child_selected = currentCombo.entries.some((entry) => entry.key === selectedEntry.key);
            }

            if( direct_child_selected ) {
                // if yes, then deselect all other direct child entries (and all of their children)
                currentCombo.entries.forEach((entry) => {
                    if(entry.key === selectedEntry.key) {
                        entry.combo_selected = true;
                        entry.selected_quantity = 1;
                    } else {
                        entry.combo_selected = false;
                        entry.selected_quantity = 0;
                        deselect_children(entry);
                    }

                    calculateState(entry);
                });
            }
        }
    }

    // this combo is selected if one of its children is selected
    currentCombo.combo_selected = child_selected;

    // store the number of selected children
    currentCombo.selected_quantity_of_childs = 0;
    if( currentCombo.entries.length > 0 ) {
        currentCombo.entries.forEach((entry) => {
            if( entry.valid ) {
                // count only valid children
                // in case of select menü, where the customer have to choose between Rinderfilet, Hirschgulasch,
                // Spätzle and Pommes
                // and the customer chooses Hirschgulasch and Spätzle and the waiter hits the Rinderfilet without
                // choosing a garstufe, it should not be counted and the order is valid
                currentCombo.selected_quantity_of_childs += entry.selected_quantity ?? 0;
            }
        });

        // if the combo is additional (that means Pommes with additional Mayo and Ketchup),
        // the number of selected children does not count for this combo (1 Pommes and 2 Mayos doesn't mean 2 Pommes)
        // otherwise the number of selected children represents the number of the combo
        if( currentCombo.combo_selection_method !== 'additional' ) {
            currentCombo.selected_quantity = currentCombo.selected_quantity_of_childs;
        }
    }

        // Adjust quantity if needed (the selected combo is the current combo)
    if( selectedEntry && currentCombo.key === selectedEntry.key ) {
        currentCombo.combo_selected = true;
        if( added ) {
            currentCombo.selected_quantity = currentCombo.selected_quantity ? currentCombo.selected_quantity + 1 : 1;

            // Check if this combo is selected but none of the children:
            // If this combo has a child marked as default, then select it directly
            if( !child_selected && currentCombo.entries.length > 0 ) {
                applyDefaults(currentCombo)
            }
        } else {
            currentCombo.selected_quantity = currentCombo.selected_quantity ? currentCombo.selected_quantity - 1 : 0;

            // If this combo is deselected, deselect all the children as well
            if( currentCombo.selected_quantity === 0 ) {
                deselect_children(currentCombo);
            }
        }

    }

    // After selecting and deselecting all child combos, calculate the state of this combo
    calculateState(currentCombo);

        // Calculate selection title
    currentCombo.selection_title = "";
    if( currentCombo.combo_min || currentCombo.combo_max ) {
        if ( currentCombo.combo_min === currentCombo.combo_max ) {
            currentCombo.selection_title = "Bitte wählen Sie " + currentCombo.combo_min + " Einträge aus ("+ currentCombo.selected_quantity + " gewählt)."
        } else {
            currentCombo.selection_title = "Bitte wählen Sie zwischen " + currentCombo.combo_min + " und " + currentCombo.combo_max + " Einträgen aus ("+ currentCombo.selected_quantity + " gewählt)."
        }
    }

    // last step, we need to calculate the price as well
    calculatePrice(currentCombo);

    return currentCombo;
}

/**
 * Recursive function, which deselect children and their children
 *
 * @param combo The combo, which needs to be deselected
 */
function deselect_children(combo) {
    combo.entries.forEach((entry) => {
        entry.selected_quantity = 0;
        deselect_children(entry);
    });
}

/**
 * If one of children (or child of the children) is marked as default, select the entry and all parent entries
 *
 * @param currentCombo the combo with children, which can be marked as default
 * @returns {boolean}   true, if one of the children (or their children) is marked as default and therefore be selected
 */
function applyDefaults(currentCombo) {
    let defaultApplied = false;
    currentCombo.entries.forEach((entry) => {
        const childDefaultApplied = applyDefaults(entry);
        if (entry.is_default || childDefaultApplied ) {
            defaultApplied = true;
            entry.combo_selected = true;
            entry.selected_quantity = 1;

            // If the entry itself or one of the children is now selected,
            // we have to recalculate the state and the price of this entry
            calculateState(entry);
            calculatePrice(entry);
        }
    });
    return defaultApplied
}

/**
 * Calculates the state (valid or not valid) depending on the selected amount and specified constraints
 *
 * @param entry The combo to validate
 */
function calculateState(entry) {
        // First check the min/max-constraints
    const selected_quantity = entry.combo_selection_method === 'additional' ? entry.selected_quantity_of_childs : entry.selected_quantity;
    const in_range_max = ( entry.combo_max ? selected_quantity <= entry.combo_max : true)
    const in_range_min = ( entry.combo_min ? selected_quantity >= entry.combo_min : true)

    entry.valid = in_range_max && in_range_min;

        // But if one child is invalid but selected, this combo is invalid as well
    if( entry.valid && entry.entries ) {
        entry.entries.forEach((childEntry) => {
           if( (childEntry.selected_quantity > 0 ) && !childEntry.valid ) {
               entry.valid = false;
           }
        });
    }

    // map the state of the entry to the preferred color
    if (entry.selected_quantity === 0) {
        entry.color = "dark";
    } else if (entry.valid) {
        entry.color = "primary";
    } else {
        entry.color = "danger";
    }
}

/**
 * Calculates the price of the given combo
 *
 * @param currentCombo the combo
 */
function calculatePrice(currentCombo) {
    // if the price calculation is number of selected children multiplied with the price of this combo,
    // we have to propagate the price of the combo to the children
    if( currentCombo.combo_price_method === "count" ) {
        applyPriceToChildren(currentCombo, currentCombo.price);
    }

    let count_valid_children = 0;
    currentCombo.children_price = 0;
    if( currentCombo.entries.length === 0 ) {
        // If there are no children, the total price of the combo is amount * base price
        currentCombo.combo_price = currentCombo.selected_quantity * currentCombo.price;
    } else {
        // we have to gather the prices of the selected children
        let additional_price = false;
        currentCombo.entries.forEach((entry) => {
            if( currentCombo.combo_price_method === "main" ) {
                // if the combo directs the price, the price for the entry is 0
                entry.display_price = 0;
            } else {
                entry.display_price = entry.price;

                if( entry.price > 0 ) {
                        // One of it has an add on price
                    additional_price = true;
                }
            }

            if (entry.selected_quantity > 0 && entry.valid) {
                // if the child is selected, take its price into account
                currentCombo.children_price += entry.combo_price;
                count_valid_children += entry.selected_quantity;
            }
        } );

        currentCombo.children_price = roundToTwo(currentCombo.children_price);

            // If one child have additional prices, all other gets information as well
        if( additional_price ) {
            currentCombo.entries.forEach((entry) => {
                if( currentCombo.combo_price_method === "children" && currentCombo.combo_max === 1) {
                    if( entry.display_price > 0 ) {
                        entry.additional_text = "price";
                    }
                } else {
                    if( entry.display_price > 0 ) {
                        entry.additional_text = "price_add";
                    } else {
                        entry.additional_text = "no_price_add";
                    }
                }
            } );
        }

        // calculate the price for the combo depending on the price method
        switch( currentCombo.combo_price_method) {
            case "children":
            case "free":
                currentCombo.combo_price = currentCombo.children_price;
                break;
            case "max":
                currentCombo.combo_price = currentCombo.children_price > currentCombo.price ? currentCombo.children_price : currentCombo.price;
                break;
            case "min":
                currentCombo.combo_price = currentCombo.children_price < currentCombo.price ? currentCombo.children_price : currentCombo.price;
                break;
            case "add":
                currentCombo.combo_price = currentCombo.children_price + currentCombo.price;
                break;
            case "count":
                currentCombo.combo_price = currentCombo.price * count_valid_children;
                break;
            default:
                currentCombo.combo_price = currentCombo.price;
        }
    }

    currentCombo.combo_price = roundToTwo(currentCombo.combo_price)

}


function applyPriceToChildren(currentCombo, price) {
    currentCombo.entries.forEach((entry) => {
        entry.price = price;
        applyPriceToChildren(entry, price);
    } );
}


function roundToTwo(num) {
    const m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
}

export {
    synchCombo
}
