/**
 * Returns a depply cloned object without reference.
 * Copied from Vue MultiSelect and Vuex.
 * @type {Object}
 */
const deepClone = function (obj) {
  if (Array.isArray(obj)) {
    return obj.map(deepClone)
    // Check if the object in the array is of type Date
  } else if (obj && typeof obj === 'object'&& !(obj instanceof Date)) {
    var cloned = {}
    var keys = Object.keys(obj)
    for (var i = 0, l = keys.length; i < l; i++) {
      var key = keys[i]
      // clone a Date object
      if (obj[key] instanceof Date) {
        cloned[key] = new Date(obj[key].valueOf())
      }  else
      cloned[key] = deepClone(obj[key])
    }
    return cloned
  } else {
    return obj
  }
}

function toCurrency(value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
}

export {toCurrency};
export default deepClone;
