<template>
  <div
      v-if="type === 'switch'"
      @click="handleFunctionCall()">
    <InputSwitch
        :id="id"
        class="mke-mr-2"
        :modelValue="selected"/>
    <label :for="id">{{label}}</label>
  </div>

  <Button
      v-else-if="posAction?.long_pressed"
      type="button"
      :id="id"
      :icon="icon"
      :iconPos="iconPos"
      :label="computedLabel"
      :class="buttonCssClass"
      :disabled="disabled"
      :badge="badge"
      :badgeClass="'p-badge-'+badgeColor"
      :title="tooltip"

      @contextmenu="suppressContextMenuOnTouch($event)"

      @pointerdown="onPointerDown($event)"

      @mouseleave="onPointerCancel($event)"
      @touchmove="onPointerCancel($event)"

      @mouseup="onPointerUp($event)"
      @touchend="onPointerUp($event)"
  />

  <Button
      v-else
      type="button"
      :id="id"
      :icon="icon"
      :iconPos="iconPos"
      :label="computedLabel"
      :class="buttonCssClass"
      :disabled="disabled"
      :badge="badge"
      :badgeClass="'p-badge-'+badgeColor"
      :title="tooltip"

      @contextmenu="suppressContextMenuOnTouch($event)"

      @click="handleFunctionCall()"
  />

</template>

<script>

import {useStore} from "vuex";
import {computed, ref} from "vue";
import {useCashPaymentStore} from "@/store/pinia/pos/CashPaymentStore";

export default {
  name: 'mkeButton',
  props: ['id', 'type', 'label', 'tooltip', 'classname', 'icon', 'iconPos', 'color', 'badge', 'badgeColor', 'size', 'fontsize',
            'posAction', 'functionCall', 'functionCallArgs', 'functionCallStore', 'labelStorePost', 'selected', 'disabled'],
  emits: ["enterButtonPressed", "resetFocus"],
  setup(props, context) {
    const store = useStore()
    const computedLabel = computed(() => {
      let resLabel = props.label
      if( resLabel && resLabel.indexOf("#") >= 0 ) {
        resLabel = replaceVariables(resLabel, false);
      }
      if (props.labelStorePost){
        resLabel = resLabel + " " + store.getters[props.labelStorePost]()
      }
      return resLabel;
    });

    const buttonCssClass = computed( () => {
      let cssClass = props.classname ? props.classname + " " : "";
      cssClass += props.color ? "p-button-" + props.color + " " : "";
      cssClass += props.fontsize ? "font-size-" + props.fontsize + " " : "";
      cssClass += props.size ? "p-button-" + props.size + " " : "";
      if( longPressedTriggered.value ) {
        // add a indicator for long pressed is triggered
        cssClass += "long-pressed ";
      }

      if( props.posAction?.long_pressed ) {
        cssClass += " with-long-press-action"
      }

      return cssClass;
    })

    function getFunctionCallStore() {
      return props.posAction?.store ?? props.functionCallStore ?? "pos-main-keyboard-display";
    }

    function getKeyBoardDisplayValue() {
      return store.state.pos.keyboardDisplay[getFunctionCallStore()];
    }

    function getKeyBoardDisplayNumber() {
      const text = getKeyBoardDisplayValue();
      if( text ) {
        return parseFloat(text.replace(",", "."));
      } else {
        return 0;
      }
    }

    function getValue(value) {
      if( value === "keyboardOrQuantity" ) {
        // Input-Field has always priority
        if (getKeyBoardDisplayValue()) {
          return getKeyBoardDisplayValue();
        } else {
          return store.state.pos.quantity;
        }
      } else if( value === "quantity" ) {
        return store.state.pos.quantity;
      } else if( value === "keyboardDisplayValue" ) {
        return getKeyBoardDisplayValue();
      } else {
        return value;
      }
    }

    function replaceVariables(text, guessQuantity) {
      let quantity = getValue("quantity");
      let keyboardDisplayValue = getValue("keyboardDisplayValue");

      if (guessQuantity && text.indexOf("#quantity#") >= 0 && text.indexOf("#keyboardDisplayValue#") < 0 && keyboardDisplayValue) {
        return text.replaceAll("#quantity#", keyboardDisplayValue);
      } else {
        text = text.replaceAll("#quantity#", quantity);
        return text.replaceAll("#keyboardDisplayValue#", keyboardDisplayValue);
      }
    }

    function callPosWithVariableResolution(url, memo) {
      url = replaceVariables(url, true);

      if( memo ) {
        url = url.replaceAll("#memo#", memo);
      }

      store.dispatch( "pos/callPos", url)
    }

    function applyCashTarget( callback ) {
      const amount = getKeyBoardDisplayNumber();

      if( store.state.pos.activeProcess &&
          ( store.state.pos.activeProcess.state === "opened" ||
              store.state.pos.activeProcess.state === "in_pay") &&
          store.state.pos.activeProcess.process_entries &&
          callback ) {
        callback(amount);
      }

      store.commit('pos/setKeyboardDisplay', {
        keyboardDisplayId: getFunctionCallStore(),
        value: ""
      })
    }

    const buttonFunctions = {
      setPosQuantity(){
        let qty = getKeyBoardDisplayValue();
        if (qty){
          store.commit("pos/setQuantity", qty)
        } else {
          store.commit("pos/setQuantity", 1)
        }
        store.commit("pos/setKeyboardDisplay", {
          keyboardDisplayId: props.functionCallStore,
          value: ""
        })
      },
      appendToKeyboardDisplay(value) {
        store.commit('pos/appendToKeyboardDisplay', {
          keyboardDisplayId: getFunctionCallStore(),
          value: value
        })
        context.emit("resetFocus")
      },
      backspaceKeyboardDisplay() {
        if (getKeyBoardDisplayValue()) {
          store.commit('pos/setKeyboardDisplay', {
            keyboardDisplayId: getFunctionCallStore(),
            value: getKeyBoardDisplayValue().slice(0, -1)
          })
        }
      },
      clearKeyboardDisplay() {
        store.commit('pos/setKeyboardDisplay', {
          keyboardDisplayId: getFunctionCallStore(),
          value: ""
        })
      },
      commitOrder() {
        let activeProcess = store.state.pos.activeProcess

        store.dispatch("pos/commitOrder", { process_id: activeProcess?.id} );
      },
      createNewProcess(name) {
        if( !name ) {
          name = getKeyBoardDisplayValue()
        }

        if( !name ) {
          name = "*";
        }
        store.dispatch("pos/createProcess", {name:name})
      },
      voidProcess() {
        let activeProcess = store.state.pos.activeProcess

        let commitVoidAction = {
          name: "callPosApi",
          param: "/pos/" + activeProcess.id + "/void_process?memo=#memo#"
        }
        store.commit('pos/setVoidAction', commitVoidAction)
      },
      processCombo(plu, quantity) {
        let activeProcess = store.state.pos.activeProcess
        if (activeProcess) {
          let processComboAction = {
            process_id: activeProcess.id,
            plu : plu,
            quantity: quantity
          }
          store.dispatch('pos/processCombo', processComboAction)
        } else {
          // If no active process is present, create a new one and order the product afterwards
          store.dispatch("pos/createProcess", {
            name: "*", successHandler: function () {
              buttonFunctions["processCombo"](plu, quantity);
            }
          });
        }
      },
      /**
       * Keyboard button helper function to help parent components to react on it
       */
      enterButtonPressed(){
        context.emit("enterButtonPressed")
      },
      filterProductsByCategory(productCategory, productCategoryFilterId) {
        if (store.state.pos.activeProductCategory[productCategoryFilterId] === productCategory.id) {
          store.commit('pos/setActiveProductCategory',
              {categoryId: null, productCategoryFilterId: productCategoryFilterId})
        } else {
          store.commit('pos/setActiveProductCategory',
              {categoryId: productCategory.id, productCategoryFilterId: productCategoryFilterId})
        }
      },
      /**
       * Order a product by plu
       * @param given_plu The plu to order
       * @param given_quantity Quantity to order (default: 1)
       */
      orderPlu(given_plu, given_quantity = 1) {

        let activeProcess = store.state.pos.activeProcess
        if (activeProcess) {
          // Depending how we gather the plu, the quantity should be retrieved in different ways
          if( given_plu !== "keyboardDisplayValue" && given_quantity === "quantity") {
            // the source of the plu is not the text-field - now the text-field has priority for the quantity
            given_quantity = "keyboardOrQuantity";
          }
          let plu = getValue(given_plu);
          let quantity = getValue(given_quantity);

          store.dispatch("pos/orderPlu", { process_id: activeProcess.id, plu : plu, quantity : quantity} );
        } else {
          // If no active process is present, create a new one and order the product afterwards
          store.dispatch("pos/createProcess", {name:"*", successHandler: function() {
            buttonFunctions["orderPlu"](given_plu, given_quantity);
          }})
        }
      },
      /**
       * Test function to reset the terminal
       */
      resetPosTerminal(){
        store.commit('waiters/set_active_terminal', null)
      },
      /**
       * Set the mode of a keyboard
       */
      setKeyboardLayoutMode(layoutMode) {
        store.commit("layout/setKeyboardLayoutMode", {
          keyboardId: props.functionCallStore,
          layoutMode: layoutMode
        })
      },
      showPosProcessListDialog() {
        store.commit('pos_process/setShowProcessListDialog', true)
      },
      switchProcess(processUUID) {
        store.dispatch("pos/openProcess", processUUID)
      },
      getSubNavigation(navigationUUID) {
        store.dispatch( "pos/setActiveNavigation", navigationUUID)
      },
      deleteProcess() {
        store.dispatch( "pos/deleteProcess")
      },
      showBill() {
        store.dispatch( "pos/showBill")
      },
      showBon() {
        store.dispatch( "pos/showBon")
      },
      showVoidDialog() {
        store.commit('pos/setVoidAction', props.posAction)
      },
      voidWithMemo(url, memo) {
        callPosWithVariableResolution(url, memo);
        store.commit('pos/setVoidAction', undefined);
      },
      startPayment() {
        store.dispatch( "pos/startPayment");
      },
      startSplit() {
        store.dispatch( "pos/startSplit");
      },
      changeTable() {
        store.commit( "pos/setSelectProcessVisibleId", "process-select-for-table-change-dialog");
        store.commit( "pos/setChangeTableVisible", true);
      },
      callPos(url) {
        callPosWithVariableResolution(url);
      },
      showProductInfo(plu) {
        plu = getValue(plu);
        store.dispatch('pos/showProductInfo', plu);
      },
      showProcessHistory() {
        store.dispatch('pos/showProcessHistory');
      },
      showProcessEntryHistory() {
        store.dispatch('pos/showProcessHistory', props.posAction);
      },
      showPaymentsInfo() {
        let activeProcess = store.state.pos.activeProcess;

        if( activeProcess.payments ) {
          store.commit("pos/setPaymentsInfo", activeProcess.payments);
        }
      },
      showProcessSignatures() {
        store.dispatch("pos/showProcessSignatures", {process:store.state.pos.activeProcess});
      },
      echo(message) {
        store.commit("general/addToast", {
          detail: message,
          life: 5000,
          severity: "info",
          summary: "Info"
        }, {root: true})
      },
      loadPosDemoData() {
        store.dispatch("pos/loadDemoData");
      },
      setLayoutLeftRightHandMode(mode) {
        store.commit("pos/setLayoutLeftRightHandMode", mode);
      },
      toggleLayoutLeftRightHandMode() {
        store.commit("pos/toggleLayoutLeftRightHandMode");
      },
      setLayoutFunctionalAreaMode(mode) {
        store.commit("pos/setLayoutFunctionalAreaMode", mode);
      },
      toggleLayoutFunctionalAreaMode() {
        store.commit("pos/toggleLayoutFunctionalAreaMode");
      },
      fastTip() {
        applyCashTarget((amount) => {
          const cashPaymentStore = useCashPaymentStore();
          if( amount < cashPaymentStore.priceToPay ) {
            cashPaymentStore.setInput("tipAmount", amount);
          } else {
            cashPaymentStore.setInput("returnAsTip", amount);
          }

        })
      },
      fastCashReturnOf() {
        applyCashTarget((amount) => {
          const cashPaymentStore = useCashPaymentStore();
          cashPaymentStore.setInput("returnOfTarget", amount);
        })
      },
      fastCashReturnTo() {
        applyCashTarget((amount) => {
          const cashPaymentStore = useCashPaymentStore();
          cashPaymentStore.setInput("returnToTarget", amount);
        })
      },
      fastCashTarget() {
        applyCashTarget((amount) => {
          const cashPaymentStore = useCashPaymentStore();
          if( amount > cashPaymentStore.priceToPay ) {
            cashPaymentStore.setInput("returnToTarget", amount);
          } else {
            cashPaymentStore.setInput("returnOfTarget", amount);
          }
        })
      },
      fastCashGiven() {
        applyCashTarget((amount) => {
          const cashPaymentStore = useCashPaymentStore();
          cashPaymentStore.setInput("givenAmount", amount);
        })
      },
      fastCash() {
        applyCashTarget((amount) => {
          const cashPaymentStore = useCashPaymentStore();
          const activeProcessId = store.state.pos.activeProcess.id;
          const activeProcessPrice = store.state.pos.activeProcess.total;
          if( amount > 0 ) {
            cashPaymentStore.setInput("givenAmount", amount);
          } else {
            cashPaymentStore.checkValidity();
          }
          if( cashPaymentStore.givenAmount >= activeProcessPrice && cashPaymentStore.isValid) {
            const paymentInfo = cashPaymentStore.paymentInfo;
            store.dispatch("pos/checkForSending", () => {
              store.dispatch("pos/call_pos_api", {
                method: "get",
                url: "/pos/" + activeProcessId + "/start_payment",
                noProcessRefresh: true,
                successHandler: function () {
                  store.dispatch("pos/payProcess", {
                    paymentInfo: {
                      process_id: activeProcessId,
                      split_type: "main",
                      ...paymentInfo
                    },
                    successCallback: (data) => {
                      store.commit("general/addToast", {
                        detail: "Der Bezahlung wurde durchgeführt",
                        life: 3000,
                        severity: "success",
                        summary: "Info"
                      } );
                      store.commit('pos/setPaymentAction', undefined);
                      store.commit('pos/setActiveProcess', data.payed_process);
                      cashPaymentStore.setPriceToPay(0);
                    }
                  });
                }
              });
            });
          }
        })
      }
    }

    // Flag inicating, that the user has long pressed on the button
    // aka touch & hold in android terms
    const longPressedTriggered = ref(false);

    const handleFunctionCall = () => {
      let button = null;
      let args = null;

      // stop long pressed detection timer
      if( longPressedTimoutHandle > 0 ) {
        console.log( "cancel long press handling")
        clearTimeout(longPressedTimoutHandle);
        longPressedTimoutHandle = 0;
      }

      // Check if the button is long pressed and get specific function call for this
      if( longPressedTriggered.value ) {
        longPressedTriggered.value = false;
        if( props.posAction && props.posAction.long_pressed ) {
          button = props.posAction.long_pressed.name;
          args = props.posAction.long_pressed.param;
        }
      }

      if( !button ) {
        // Get the specific function call for a normal click/tap
        if( props.posAction ) {
          button = props.posAction.clicked ? props.posAction.clicked.name : props.posAction.name;
          args = props.posAction.clicked ? props.posAction.clicked.param : props.posAction.param;
        } else {
          button = props.functionCall;
          args = props.functionCallArgs;
        }
      }

      if( button && buttonFunctions[button] ) {
          if (args) {
            if (args instanceof Array) {
              buttonFunctions[button](...args)
            } else {
              buttonFunctions[button](args)
            }
          } else {
            buttonFunctions[button]()
          }
      } else if( button ) {
        console.log("WARNING! Trying to trigger unknown function " + button + " with ", args);
      }
    }

    // First press on a button starts a timeout, if the timeout is reached, then it's a long press
    // This is the handle for the timeout, which
    // a) is used for canceling the timeout, in case the user unpress the button earlier (aka click/tap)
    // b) is an indicator that a timeout is started and thererfore no need to fire it again
    let longPressedTimoutHandle = 0;

    // eslint-disable-next-line no-unused-vars
    const onPointerDown = (event) => {
      // the user starts pressing the button (mousedown or touchstart)
      // if the button has a longPressed action, we start the timeout and suppress the further
      // handling of this event - because on touchdevices otherwise the os dependent actions are triggered
      // and we never receive a touchend event.
      if( props.posAction && props.posAction.long_pressed ) {
        if( longPressedTimoutHandle === 0 ) {
          // start pressing means: no long press
          longPressedTriggered.value = false;
          longPressedTimoutHandle = setTimeout(function() {
            // but pressing a while means: long press
            longPressedTriggered.value = true;
          }, 500);
        }
      }
    }

    // eslint-disable-next-line no-unused-vars
    const onPointerUp = (event) => {
      // The user releases the button
      // If we have started a timeout, than the long press detection was started
      // and by suppressing all the events, we've abandoned the normal click event process
      // so we need to trigger the "click" action manually.
      if( longPressedTimoutHandle > 0 ) {
        clearTimeout(longPressedTimoutHandle);
        longPressedTimoutHandle = 0;
        handleFunctionCall();
      }
    }

    // eslint-disable-next-line no-unused-vars
    const onPointerCancel = (event) => {
      // On desktop, we receive the event, if the user leaves the button and we can stop all the processing
      // On touch, this doesn't work and we probably have to listen on the touchmove event and if the current
      // position of the touch is far far away from the button, we can stop the process.
      // at the moment, the long pressed action is triggered.
      if( longPressedTimoutHandle > 0 ) {
        clearTimeout(longPressedTimoutHandle);
        longPressedTimoutHandle = 0;
      }
      longPressedTriggered.value = false;
    }

    const suppressContextMenuOnTouch = (event) => {
      if( event.pointerType !== "mouse" ) {
        event.preventDefault();
      }
    }

    return {handleFunctionCall, buttonCssClass, computedLabel, onPointerDown, onPointerUp, onPointerCancel, suppressContextMenuOnTouch}
  }
}
</script>