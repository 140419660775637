<template>
  <div class="mx-topbar" >
    <div class="mx-topbar-left">
      <slot name="left">
        <Button icon="mdi mdi-menu mdi-2x" @click="mobileMenuVisible = true" class="p-button-transparent p-button-rounded mke-mr-4 p-d-xl-none" />
      </slot>
    </div>
    <div class="mx-topbar-center">
      <slot name="center"></slot>
    </div>
    <div class="mx-topbar-right">
      <slot name="right">
        <div id="clock" class="time body-14-regular">{{clock}}</div>
        <mke-switch-theme-toggle/>
        <Button
            class="p-button-transparent"
            type=""
            icon="mdi mdi-bell-outline mdi-2x"
        />
        <div class="mx-user-profile p-ripple" v-ripple @click="toggleUserMenu">
          <Avatar
              v-if="activeUser && activeUser.user_image"
              size="large"
              :image="require('@/assets/images/avatar.jpg')"
              shape="circle"
          />
          <!-- TODO user_image -->
          <Avatar
              v-else
              size="large"
              icon="mdi mdi-account-outline"
              shape="circle"
          />
          <div class="user_name mke-ml-4">
            <span class="body-14-bold p-d-block">
              {{activeUser?.user_name ?? '?'}}
            </span>
          </div>
        </div>
        <Menu ref="userMenu" :model="userMenuItems" :popup="true" />
      </slot>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useMq } from "vue3-mq";

export default {
  name: "MxTopBar",
  setup() {
    const store = useStore();
    const { t,d } = useI18n({ useScope: "global" });
    const mq = useMq();

    const activeUser = computed(() => store.state.general.activeUser);

    const mobileMenuVisible = computed({
      get: () => {
        return store.state.admin.mobile_menu_visible;
      },
      set: (value) => store.commit("admin/set_mobile_menu_visible", value),
    });

    const userMenu = ref();
    const userMenuItems = ref([
      {
        label: t("message.home.navigate"),
        items: [
          {
            label: t("message.home.logout"),
            icon: "mdi mdi-logout",
            command: () => {
              store.dispatch("general/logout");
            },
          },
        ],
      },
    ]);

    const toggleUserMenu = (event) => {
      userMenu.value.toggle(event);
    };

    const clock = ref( "");

    const get_now = () =>  {
      const today = new Date();
      clock.value = d(today, "datetime_long")
    }

    onMounted(() => {
      get_now();
      setInterval(() => {
        get_now();
      }, 1000);
    })

    return {
      userMenuItems,
      userMenu,
      toggleUserMenu,
      get_now,
      activeUser,
      mobileMenuVisible,
      clock,
      t,
      d,
      mq
    };
  }
};
</script>
