<template>
  <div>
    <mke-button
        id="button-model-combo-preview"
        :label="t('message.masterdata.button.preview')"
        class="p-button-primary no-shrink mke-ml-2"
        @click="previewCombo"
    />
    <mke-button
    id="button-model-combo-save"
    :label="t('message.masterdata.button.save')"
    class="p-button-primary no-shrink mke-ml-2"
    @click="saveCombo"
    />
    <PosFormBuilderComboPanel
        v-if="panelVisible"
        :id="rootCombo.key"
        :combo="rootCombo"
        :level="1"
        @modelValueUpdate="onModelValueUpdate"
        @editCombo="editCombo"
        @addProduct="addProduct"
        @removeCombo="removeCombo"
        @moveComboUp="moveComboUp"
        @moveComboDown="moveComboDown"
    />
    <PosFormBuilderComboEditDialog
        v-if="comboToEdit !== undefined"
        :comboToEdit="comboToEdit"
        @comboCanceled="comboCanceled"
        @comboComitted="comboCommitted"
    />
    <mkeFormBuilderAddItemDialog
        :visible="addProductVisible"
        title="message.masterdata.dialogs.add_product"
        endpoint="products"
        @onItemAddCommitted="productAddCommitted"
        @onItemAddCanceled="productAddCanceled"
    />
    <PosProcessComboDialog/>

  </div>
</template>

<script>

import {ref} from "vue";
import deepClone from "@/utilities";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {scrollToBottom} from "@/plugins/scrolltobottom";


export default {
  name: "PosFormBuilderComboEdit",
  props: ["id", "data", "options", "store", "activeID", "level", "model", "field", "action"],
  emits: ["update:modelValue"],

  setup(props,context) {
    const store = useStore()
    const rootCombo = ref({});
    const panelVisible = ref(false);
    const comboToEdit = ref( undefined );
    const addProductVisible = ref( false );
    const { t } = useI18n({ useScope: 'global' })

    if( props.model && props.field && props.action && props.activeID ) {
      store.dispatch(props.store + "/getTreeData",{
        id: props.activeID,
        model: props.model,
        field: props.field,
        action: props.action,
        onSuccessCallback: (newData) => {
          rootCombo.value = deepClone(newData);
          panelVisible.value = true;
        }
      });

    }

    /**
     * Triggered on model value update.
     */
    function onModelValueUpdate() {
      context.emit("modelValueUpdate")
    }

    function editCombo(combo) {
      const data = combo.value ? combo.value : combo;
      if( data.entries ) {
        data.combo_selection_method = data.combo_selection_method || 'combinable';
        data.combo_price_method = data.combo_price_method || 'main';
      }
      comboToEdit.value = data;
    }

    function addProduct() {
      addProductVisible.value = true;
    }

    function productAddCommitted(products) {
      addProductVisible.value = false;
      products.forEach( (product) => {
        store.dispatch(props.store + "/getTreeData",{
          id: product.id,
          model:"products",
          field: "combo",
          action: "getCombo",
          onSuccessCallback: (newData) => {
            const newEntry = deepClone(newData);
            newEntry.index = rootCombo.value.entries.length + 1;
            rootCombo.value.entries.push( newEntry );
            setTimeout( function() { scrollToBottom(".tree-panel .tree-panel:last-child") }, 50);
          }
        });
      });
    }

    function productAddCanceled() {
      addProductVisible.value = false;
    }

    function removeCombo(combo) {
      const indexToRemove = rootCombo.value.entries.findIndex((c) => c.index === combo.index);

      rootCombo.value.entries.splice(indexToRemove, 1);
      rootCombo.value.entries.forEach((child, index) => {
        child.index = index + 1;
      });
    }


    function comboCommitted(editedCombo) {
      comboToEdit.value.title = editedCombo.value.title;
      comboToEdit.value.combo_min = editedCombo.value.combo_min;
      comboToEdit.value.combo_max = editedCombo.value.combo_max;
      comboToEdit.value.combo_price = editedCombo.value.combo_price;
      comboToEdit.value.is_default = editedCombo.value.is_default;
      comboToEdit.value.combo_selection_method = editedCombo.value.combo_selection_method;
      comboToEdit.value.combo_price_method = editedCombo.value.combo_price_method;
      comboToEdit.value = undefined;
    }

    function comboCanceled() {
      comboToEdit.value = undefined;
    }

    function saveCombo() {
      store.dispatch(props.store + "/saveAdditionalData",{
        uri: 'products/' + props.activeID + '/saveCombo',
        data: rootCombo.value,
        successCallback: (newData) => {
          rootCombo.value = deepClone(newData);
          panelVisible.value = true;
          console.log( "Updated combo", rootCombo.value);
        }
      });
    }

    function moveCombo(combo, offset) {
      const currentIndex = combo.index;
      const newIndex = currentIndex + offset;
      const comboToSwap = rootCombo.value.entries.find((c) => c.index === newIndex);

      combo.index = newIndex;
      comboToSwap.index = currentIndex;
    }

    function moveComboUp(combo) {
      moveCombo(combo, -1);
    }

    function moveComboDown(combo) {
      moveCombo(combo, 1);
    }

    function previewCombo() {
      let processComboAction = {
        rootCombo: rootCombo.value
      }
      store.commit("pos/setProcessComboAction", processComboAction);
    }

    return {t, panelVisible, rootCombo, editCombo, comboToEdit,
      addProduct, addProductVisible, productAddCommitted, productAddCanceled,
      removeCombo, moveComboUp, moveComboDown,
      onModelValueUpdate, comboCommitted, comboCanceled, saveCombo, previewCombo};
  }
}
</script>

<style scoped></style>
