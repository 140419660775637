<template>
  <Dialog
      id="payment-dialog"
      :modal="true"
      v-model:visible="paymentDialogVisible"
      :closable="false"
      :style="{width: '90vw'}">

      <template #header>
        <h5>Bezahlung von Vorgang {{splitStore.sourceProcess.name}}</h5>
      </template>

      <PosProcessSplitEntries id="split-payment"
                              mode="payment"
                              :numpadState="numpadState"
                              @split:added="addSplit"
                              @split:selected="splitSelected"
                              @numpad:consumed="numpadConsumed"/>
      <Divider layout="vertical" />

      <div class="p-d-flex p-flex-column p-jc-between gap-3">
        <div class="p-d-flex p-flex-column flex-grow p-jc-between mx-card" v-if="payMode==='start' && !currentPayment">
          <PosProcessPaymentCash id="payment"

                               :numpadState="numpadState"
                               @numpad:consumed="numpadConsumed"
                               @payment:changed="paymentChanged($event)"/>

          <div v-if="payMode==='start' "
              class="p-d-flex p-flex-column gap-2">
              <div class="p-d-flex mx-fg-text-medium">Zahlungsart</div>
              <div class="p-d-flex gap-2 p-jc-between">
                <div class="p-d-flex flex-1 p-jc-around p-ai-center p-flex-column gap-1">
                  <mke-button icon="mdi mdi-currency-eur" color="primary" class="w-100"/>
                  <span>Bar</span>
                </div>
                <div class="p-d-flex flex-1 p-jc-around p-ai-center p-flex-column gap-1">
                  <mke-button icon="mdi mdi-credit-card" class="w-100" />
                  <span>EC/Kredit</span>
                </div>
                <div class="p-d-flex flex-1 p-jc-around p-ai-center p-flex-column gap-1">
                  <mke-button icon="mdi mdi-gift" class="w-100" />
                  <span>Gutschein</span>
                </div>
                <div class="p-d-flex flex-1 p-jc-around p-ai-center p-flex-column gap-1">
                  <mke-button icon="mdi mdi-dots-horizontal" class="w-100" />
                  <span>Weitere</span>
                </div>
              </div>
          </div>
        </div>
        
        <PosNumpadCash id="pos-process-payment-cash-numpad"
                       v-if="payMode==='start' && !currentPayment && isSplitEnabled"
                       ref="numpad"
                       :value="numpadState"
                       numpad-input-store="numpadCashInput"
                       @numpad:change="numpadChanged"/>

        <transition-group name="fade-in-from-left" mode="out-in">
          <!-- waiting / loading ... -->
          <div v-if="payMode === 'paying'" class="mx-card bordered mx-fg-warning-medium text-align-center mke-py-6" style="flex:1">
            <div class="p-d-flex p-ai-center p-jc-center p-flex-column h-100">
              <mke-spinner
                  class=""
                  size="65"
              />
              <div class="font-size-m bold mke-mt-4">In Beabeitung…</div>
            </div>
          </div>



          <!-- success / payed -->
          <div v-if="currentPayment" class="mx-card bordered mx-fg-success-medium text-align-center mke-py-6" style="flex: 1">
            <span style="font-size: 65px;" class="mdi mdi-check-circle"></span>
            <div class="font-size-m bold mke-mt-1 mke-mb-6">Zahlung erfolgreich</div>
            <div v-if="currentPayment.returned > 0" class="p-d-flex p-flex-column gap-2 font-size-l bold mx-fg-text-high mke-mb-6" >
              <span>Rückgeld: {{n(currentPayment.returned, 'currency')}}</span>
            </div>
            <div class="p-d-flex mx-fg-text-high p-flex-column gap-1">
<!--              <div class="p-d-flex">-->
<!--                <div class="mx-fg-text-medium flex-1 text-align-left">Transaktions-Datum</div>-->
<!--                <div class="flex-1 text-align-right">{{currentPayment.timestamp}}</div>-->
<!--              </div>-->
              <div class="p-d-flex">
                <div class="mx-fg-text-medium flex-1 text-align-left">Zahlungs-Art</div>
                <div class="flex-1 text-align-right">{{currentPayment.transaction_type}}</div>
              </div>
              <div class="p-d-flex" v-if="currentPayment.price > 0">
                <div class="mx-fg-text-medium flex-1 text-align-left">Gesamt-Betrag</div>
                <div class="flex-1 text-align-right">{{n(currentPayment.price, 'currency')}}</div>
              </div>
              <div class="p-d-flex" v-if="currentPayment.given > 0">
                <div class="mx-fg-text-medium flex-1 text-align-left">Gegeben</div>
                <div class="flex-1 text-align-right">{{n(currentPayment.given, 'currency')}}</div>
              </div>
              <div class="p-d-flex" v-if="currentPayment.tip > 0">
                <div class="mx-fg-text-medium flex-1 text-align-left">Tipp</div>
                <div class="flex-1 text-align-right">{{n(currentPayment.tip, 'currency')}}</div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>

      <template #footer>
        <mke-button v-if="payMode !== 'payed'"
                    id="button-payment-cancel"
                    label="Abbrechen"
                    icon="pi pi-times"
                    @click="closeDialog"/>
        <mke-button v-if="payMode !== 'payed'"
            id="button-payment-commit"
            label="Bezahlen"
            icon="pi pi-money-bill"
            class="p-button-primary"
            :disabled="!paymentInfo.valid || isSplitDisabled" @click="commitPayment" />
        <mke-button v-else
                    id="button-payment-cancel"
                    label="Schliessen"
                    icon="pi pi-check"
                    class="p-button-primary"
                    @click="closeDialog"/>
      </template>
  </Dialog>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";
import {useSplitEntriesStore} from "@/store/pinia/pos/SplitEntriesStore";
import {useI18n} from "vue-i18n";
import {useCashPaymentStore} from "@/store/pinia/pos/CashPaymentStore";
import {storeToRefs} from "pinia";
import deepClone from "@/utilities";

export default {
  name: "PosProcessPaymentDialog",
  setup(){
    const store = useStore();
    const {n} = useI18n();

    const paymentDialogVisible = computed( () => store.state.pos.paymentAction !== undefined );

    const splitStore = useSplitEntriesStore();
    const cashPayStore = useCashPaymentStore();

    const {
      paymentInfo
    } = storeToRefs(cashPayStore);

    const numpad = ref(null);
    const numpadState = ref({value:0, text: '', all: false});

    const payMode = ref("start");

    const processCompleted = ref(false);

    const isSplitEnabled = computed( () => splitStore.isSplitEnabled(splitStore.currentSplit));
    const isSplitDisabled = computed( () => splitStore.isSplitDisabled(splitStore.currentSplit));

    function addSplit() {
      const newProcess = {name: "" + (splitStore.noOfSplit+1)}
      splitStore.addSplit(newProcess);
      cashPayStore.setPriceToPay(0);
      payMode.value = "start";
      currentPayment.value = splitStore.currentPayment()

    }

    function splitSelected({price}) {
      cashPayStore.setPriceToPay(price);
      if( !processCompleted.value ) {
        payMode.value = "start";
      }
      currentPayment.value = splitStore.currentPayment()

    }

    function numpadChanged(state) {
      numpadState.value = state;
    }

    function numpadConsumed() {
      numpad.value.consumed();
    }

    const paymentChanged = (data) => {
      paymentInfo.value = data;
    }

    const currentPayment = ref(undefined);


    const commitPayment = () => {
      payMode.value = "paying";

      const splitInfo = deepClone(splitStore.getCurrentSplitInfo());
      store.dispatch("pos/payProcess", {
        paymentInfo: {
          process_id: store.state.pos.paymentAction.process.id,
          lines: splitInfo.entries,
          split_type: splitInfo.typeOfSplit,
          ...paymentInfo.value
        },
        successCallback: (data) => {
          payMode.value = "payed";

          processCompleted.value = (data.source_process.state === "closed");

          if( paymentInfo.value.returned > 0 && splitInfo.typeOfSplit === "main") {
            splitStore.applyPaymentToSplit(0, calculatePaymentInfo(data));
            currentPayment.value = splitStore.currentPayment()
          } else {
            splitStore.applyPaymentToSplit(splitInfo.split, calculatePaymentInfo(data));
            splitStore.disableSplit(splitInfo.split);
            splitStore.updateEntries(data.source_process.process_entries);
            cashPayStore.setPriceToPay(splitStore.getCurrentSplitInfo().price);
            currentPayment.value = splitStore.currentPayment()

          }

        }
      });

    };

    function calculatePaymentInfo(data) {
      if( data.payed_process && data.payed_process.payments ) {
        const payment = data.payed_process.payments[0];
        const paymentInfo = {
          price: payment.price,
          given: payment.given,
          returned: payment.returned,
          tip: payment.tip,
          transaction_type: payment.payments[0].payment_type,
          transaction_no: payment.transaction_no,
          timestamp: payment.timestamp_end
        }
        return paymentInfo;
      } else {
        return undefined;
      }
    }

    const closeDialog = () => {
      store.dispatch("getOpenProcesses");

      if( store.state.pos.activeProcess.state === "in_pay" ) {

        store.dispatch("pos/cancelPayment", {
          paymentInfo: {
            process_id: store.state.pos.paymentAction.process.id
          },
          successCallback: () => {

            store.commit("general/addToast", {
              detail: "Der Bezahlung wurde abgebrochen",
              life: 3000,
              severity: "error",
              summary: "Info"
            } );
          }
        });
      } else {
        // Dialog schliessen
        store.commit('pos/setPaymentAction', undefined);
        cashPayStore.setPriceToPay(0);

      }
    }

    return {
      n, paymentDialogVisible, commitPayment, numpad, numpadChanged, numpadConsumed, numpadState, paymentChanged, paymentInfo, closeDialog, addSplit, splitStore, splitSelected, payMode, isSplitDisabled, isSplitEnabled, currentPayment
    }
  }
}

</script>

<style scoped>

</style>
