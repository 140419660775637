import axios from "axios";
import {scrollToBottom} from "@/plugins/scrolltobottom";
import {synchCombo} from "@/store/modules/combo";
import {useSplitEntriesStore} from "@/store/pinia/pos/SplitEntriesStore";
import {useCashPaymentStore} from "@/store/pinia/pos/CashPaymentStore";

const restUrlPosService = config.PosServiceUrl
const restUrlDataService = config.DataServiceUrl

const defaultLayoutLeftRightHandMode = "right";
const defaultLayoutFunctionalAreaMode = "simple";

// initial state
function getStartState() {
    return {
        activeProcess: null,
        activeProductCategory: {},
        activeTerminal: null,
        layoutLeftRightHandMode: defaultLayoutLeftRightHandMode, // left or right
        layoutFunctionalAreaMode: defaultLayoutFunctionalAreaMode, // outer, inner, simple, standard
        keyboardDisplay: {},
        processListMode: "open",
        processList: {},
        productCategories: [],
        navigations: [],
        products: [],
        quantity: 1,
        terminals: [],
        productForInfo: null,
        compressed: true,
        voidAction: undefined,
        processDetailsExpanded: false,
        processComboAction: undefined,
        processHistory: undefined,
        paymentAction: undefined,
        splitAction: undefined,
        tseInfo: undefined,
        paymentsInfo: undefined,
        selectProcessVisibleId: undefined,
        changeTableVisible: false,
        posInstallationMode: "installed"
    }
}

function getDefaultState() {
    /**
     * Remove persisted state fields from default state
     */
    let res = getStartState()
    return res
}

const state = getStartState();

// getters
const getters = {
    getKeyboardDisplay: (state) => (keyboardDisplayId) => {
        if (!state.keyboardDisplay[keyboardDisplayId]){
            return "";
        }
        return state.keyboardDisplay[keyboardDisplayId];
    },
    getQuantity: (state) => () => {
        return state.quantity
    },
    processList: (state) => () => {
        return [...Object.values(state.processList)];
    },
    layoutCssClass: (state) => () => {
        const layoutFunctionalAreaMode = state.layoutFunctionalAreaMode ?? defaultLayoutFunctionalAreaMode;
        const layoutLeftRightHandMode = state.layoutLeftRightHandMode ?? defaultLayoutLeftRightHandMode;
        return  layoutFunctionalAreaMode + "-" + layoutLeftRightHandMode + "-hand";
    }
}

// actions
const actions = {
    processUpdated({state, commit, dispatch}, event) {
        const currentProcessId = state.activeProcess ? state.activeProcess.id : 'this-is-no-uuid';
        if( currentProcessId === event.process_id ) {
            if( ["process_deleted"].includes(event.topic) ) {
                commit("setActiveProcess", null);
            } else {
                dispatch("refreshProcess", currentProcessId);
            }
        } else if( event.topic === "process_created" ) {
            commit("setProcess", event.value );
        } else if( event.topic === "order_entry_created" || event.topic === "void_entry_created") {
            commit("processEntryCreated", {processId:event.process_id, item: event.value} );
        } else if( event.topic === "order_commited" || event.topic === "process_updated" || event.topic === "process_inpay" ) {
            commit("setProcess", event.value);
        } else if( event.topic === "order_sended" ) {
            commit("setProcess", event.value.process );
        } else if( event.topic === "process_deleted" ) {
            commit("processDeleted", {processId:event.process_id, item: event.value} );
        } else if( event.topic === "process_paid" ) {
            if( state.processListMode === "open" ) {
                commit("processDeleted", {processId:event.process_id, item: event.value} );
            } else {
                commit("setProcess", event.value);
            }
        }
    },

    call_pos_api(state, payload) {
        const commit = state.commit;

        let url = restUrlPosService + payload.url;
        const compressed = "compressed" in payload ? payload.compressed : state.state.compressed;

        if( compressed ) {
            url += url.indexOf("?") > 0 ? "&" : "?"
            url += "compress=True"
        }
        const requestConfig = {
            method: payload.method,
            url: url,
            data: payload.data
        }

        // console.log( "CALL_POS_API", requestConfig);

        axios.request(requestConfig)
            .then(function(result) {
                if( result.status === 200 ) {
                    if( !payload.noProcessRefresh ) {
                        commit("setActiveProcess", result.data);

                        if (result.data && result.data.message) {
                            commit("general/addToast", {
                                detail: result.data.message,
                                life: 3000,
                                severity: "info",
                                summary: "Info"
                            }, {root: true})
                        }
                    }
                    if( payload.successHandler ) {
                        payload.successHandler(state, result.data);
                    }
                } else {
                    if( payload.failureHandler ) {
                        payload.failureHandler(state, result);
                    } else {
                        commit("general/addToast", {
                            detail: result.statusText,
                            life: 10000,
                            severity: "error",
                            summary: "Error"
                        }, {root: true})
                    }
                }
            })
            .catch(function (error) {
                let message = error.response && error.response.data && error.response.data.detail ? error.response.data.detail : error.message;
                commit("general/addToast", {
                    detail: message,
                    life: 10000,
                    severity: "error",
                    summary: "Error"
                }, {root: true})
            });
    },
    call_data_api(state, payload) {
        let commit = state.commit
        const requestConfig = {
            method: payload.method,
            url: restUrlDataService + payload.url,
            data: payload.data
        }
        axios.request(requestConfig)
            .then(function(result) {
                if( result.status === 200 ) {
                    if( payload.successHandler ) {
                        payload.successHandler(state, result.data);
                    }
                } else {
                    if( payload.failureHandler ) {
                        payload.failureHandler(state, result);
                    } else {
                        commit("general/addToast", {
                            detail: result.statusText,
                            life: 10000,
                            severity: "error",
                            summary: "Error"
                        }, {root: true})
                    }
                }
            })
            .catch(function (error) {
                commit("general/addToast", {
                    detail: error.message,
                    life: 10000,
                    severity: "error",
                    summary: "Error"
                }, {root: true})
            });
    },
    /**
     * Load demo data. For development purpose only!
     * @param commit
     */
    loadDemoData({commit,dispatch}) {
        commit("setPosInstallationMode", "installing");
        dispatch("call_data_api", {
            method: "get",
            url: "/import/pos_demo_data",
            successHandler: function() {
                location.reload();
            }
        });
    },
    /**
     * Some functions needs ordering the current process before executing the function,
     * for example, before switching to another process, order the current process.
     * This function checks, if a sending is needed and executes the function after sending.
     *
     * @param state the current state, required for checking the active process
     * @param dispatch this actions maybe needs to dispatch the ordering of the current process
     * @param functionToCall the function to call after successfull sending or directly, if no send is required
     */
    checkForSending({state, dispatch}, functionToCall) {
        // First check, if we have a process with newly added entries open
        let notSendEntry = undefined;
        if( state.activeProcess && state.activeProcess.id ) {
            notSendEntry = state.activeProcess.process_entries?.find(entry => entry.state === "new" );
        }

        if( notSendEntry ) {
            // if so, order the process without refreshing the UI,
            // but after send, trigger the passed function
            dispatch("call_pos_api", {
                method: "put",
                url: "/pos/" + state.activeProcess.id + "/commit_order",
                noProcessRefresh: true,
                successHandler: functionToCall
            });
        } else {
            // No send required, so trigger the passed function
            functionToCall();
        }
    },
    /**
     * Create a new process - checks, if the current process should be ordered int the before hand
     * @param dispatch - dispatches the check and creation of the process
     */
    createProcess({dispatch}, {name, successHandler}) {
        dispatch("checkForSending", () => {
            dispatch("call_pos_api", {
                method: "post",
                url: "/pos/open/" + name,
                successHandler: successHandler
            });
        } );
    },
    /**
     * Opens the given process - - checks, if the current process should be ordered int the before hand
     * @param dispatch - dispatches the check and switching of the process
     * @param processUUID - the process to switch to
     */
    openProcess({dispatch}, processUUID) {
        dispatch("checkForSending", () => {
            dispatch("call_pos_api", {
                method: "get",
                order: 500,
                url: "/pos/" + processUUID
            });
        });
    },
    /**
     * Refresh the given process without checking if the current process should be ordered int the before hand,
     * because this is triggered by outside and should not send orders or change current input in the textfield
     * @param dispatch - dispatches the check and switching of the process
     * @param processUUID - the process to switch to
     */
    refreshProcess({dispatch, commit}, processUUID) {
        dispatch("call_pos_api", {
            method: "get",
            url: "/pos/" + processUUID,
            order: 1000,
            noProcessRefresh: true,
            successHandler: (state, process) => {
                commit("refreshActiveProcess", process)
            }
        });
    },
    setCompressed({state, dispatch, commit}, compressed) {
        if( state.activeProcess ) {
            dispatch("call_pos_api", {
                method: "get",
                url: "/pos/" + state.activeProcess.id,
                compressed: compressed,
                successHandler: function() {
                    commit("setCompressed", compressed);
                }
            });
        } else {
            commit("setCompressed", compressed);

        }
    },
    updateProcess({dispatch, commit}, {process, values}) {
        let queryString = Object.keys(values).map(key => key + '=' + values[key]).join('&');

        if( queryString ) {
            dispatch("call_pos_api", {
                method: "put",
                url: "/pos/" + process.id + "/update?" + queryString,
                successHandler: () => {
                    let message = "processUpdated";
                    if ("inhouse" in values) {
                        if (values.inhouse) {
                            message = "In Haus"
                        } 
                        else {
                            message = "Außer Haus"
                        }
                    }
                    commit("general/addToast", {
                        detail: message,
                        life: 3000,
                        severity: "info",
                        summary: "Info"
                    }, {root: true})
                }
            });
        }
    },

    orderPlu({dispatch}, {process_id, plu, quantity}) {
        // PUT http://localhost:54321/pos/{{process_uuid}}/order/3002
        dispatch("call_pos_api", {
            method: "put",
            url: "/pos/" + process_id + "/order/" + plu + "?quantity=" + quantity,
            order: 10,
            process_id: process_id,
            plu: plu,
            quantity: quantity,
            type: "order_entry",
            successHandler: () => {
                setTimeout( function() { scrollToBottom("#pos__process-details .mx-process-detail-item:last-child") }, 50);
            }
        });
    },
    voidProcess({dispatch}, {process_id}) {
        dispatch("call_pos_api", {
            method: "put",
            url: "/pos/" + process_id + "/void_process?memo=Tisch-Storno"
        });
    },
    processCombo({dispatch}, {process_id, plu, quantity}) {
        dispatch("call_data_api", {
            method: "get",
            url: "/products/" + plu + "/processCombo",
            successHandler: function({commit}, data) {
                let processComboAction = {
                    process_id: process_id,
                    plu : plu,
                    quantity: quantity,
                    rootCombo: data
                }
                if(data.combo_selection_method==="not_combinable" ) {
                    let updatedCombo = synchCombo(processComboAction.rootCombo, null, 1, false);
                    processComboAction.rootCombo = updatedCombo
                    dispatch("commitCombo", processComboAction)
                } else {
                    commit("setProcessComboAction", processComboAction);
                }
            }
        });
    },
    commitCombo({dispatch, commit}, comboModel) {
        dispatch("call_pos_api", {
            method: "put",
            url: "/pos/" + comboModel.process_id + "/order_combo",
            data: comboModel.rootCombo,
            successHandler: function() {
                commit('setProcessComboAction', undefined);
                setTimeout( function() { scrollToBottom("#pos-transactions .product-item:last-child") }, 50);
            }
        });

    },
    commitOrder({commit, dispatch}, {process_id}) {
        if (process_id) {
            dispatch("call_pos_api", {
                method: "put",
                order: 100,
                url: "/pos/" + process_id + "/commit_order"
            });
        } else {
            commit("general/addToast", {
                detail: 'No active process',
                life: 3000,
                severity: "error",
                summary: "Error"
            }, {root: true})
        }

    },
    deleteProcess({state, commit, dispatch}) {
        if (state.activeProcess) {
            let process_id = state.activeProcess.id
            dispatch("call_pos_api", {
                method: "delete",
                url: "/pos/" + process_id,
                successHandler: function({dispatch}) {
                    dispatch("getOpenProcesses");
                }
            });
        } else {
            commit("general/addToast", {
                detail: 'No active process',
                life: 3000,
                severity: "error",
                summary: "Error"
            }, {root: true})
        }
    },
    showBill({state, commit}) {
        if (state.activeProcess) {
            let process_id = state.activeProcess.id
            window.open(restUrlPosService + "/pos/" + process_id + "/bill", "_blank","popup=yes");
        } else {
            commit("general/addToast", {
                detail: 'No active process',
                life: 3000,
                severity: "error",
                summary: "Error"
            }, {root: true})
        }
    },
    showBon({state, commit}) {
        if (state.activeProcess) {
            let process_id = state.activeProcess.id
            window.open(restUrlPosService + "/pos/" + process_id + "/bon", "_blank", "popup=yes");
        } else {
            commit("general/addToast", {
                detail: 'No active process',
                life: 3000,
                severity: "error",
                summary: "Error"
            }, {root: true})
        }
    },
    startPayment({state, commit, dispatch}) {
        if (state.activeProcess) {
            dispatch("checkForSending", () => {
                dispatch("call_pos_api", {
                    method: "get",
                    url: "/pos/" + state.activeProcess.id + "/start_payment",
                    noProcessRefresh: true,
                    successHandler: function ({commit}, data) {
                        const paymentAction = {process: data};
                        commit("setPaymentAction", paymentAction);
                    }
                });
            });
        } else {
            commit("general/addToast", {
                detail: 'No active process',
                life: 3000,
                severity: "error",
                summary: "Error"
            }, {root: true})
        }
    },
    cancelPayment({dispatch}, {paymentInfo, successCallback}) {
        dispatch("call_pos_api", {
            method: "get",
            url: "/pos/" + paymentInfo.process_id + "/cancel_payment",
            successHandler: function({commit}) {
                commit("setPaymentAction", undefined);
                if( successCallback ) {
                    successCallback();
                }
            }
        });

    },
    startSplit({state, commit, dispatch}) {
        if (state.activeProcess) {
            dispatch("checkForSending", () => {
                dispatch("call_pos_api", {
                    method: "put",
                    url: "/pos/" + state.activeProcess.id + "/change_state",
                    data: {state: "in_split"},
                    compress: true,
                    noProcessRefresh: true,
                    successHandler: function({commit}, data) {
                        const splitAction = {process: data};
                        commit("setSplitAction", splitAction);
                        commit("setSelectProcessVisibleId", "process-select-for-split-dialog");
                    }
                });
            });
        } else {
            commit("general/addToast", {
                detail: 'No active process',
                life: 3000,
                severity: "error",
                summary: "Error"
            }, {root: true})
        }
    },
    cancelSplit({dispatch}, {splitInfo, successCallback}) {
        dispatch("call_pos_api", {
            method: "put",
            url: "/pos/" + splitInfo.process_id + "/change_state",
            data: {state: "opened"},
            successHandler: function({commit}) {
                commit("setSplitAction", undefined);
                if( successCallback ) {
                    successCallback();
                }
            }
        });

    },
    callPos({dispatch}, url) {
        dispatch("call_pos_api", {
            method: "put",
            url: url
        });
    },
    getNavigationForTarget({dispatch}, {target, mode, successHandler}) {
        dispatch("call_data_api", {
            method: "get",
            url: "/pos_navigations/for/" + target + "?mode=" + mode,
            successHandler: (state, data) => {
                if( successHandler ) {
                    successHandler(data);
                }
            }
        });
    },
    getNavigation({dispatch}, {id, successHandler}) {
        dispatch("call_data_api", {
            method: "get",
            url: "/pos_navigations/" + id +  "/full",
            successHandler: (state, data) => {
                if( successHandler ) {
                    successHandler(data);
                }
            }
        });
    },
    showProductInfo({dispatch}, plu) {
        dispatch("call_data_api", {
            method: "get",
            url: "/products/" + plu + "/plu",
            successHandler: function({commit}, data) {
                commit("productForInfo", data);
            }
        });
    },
    showProcessHistory({state, dispatch}, posAction) {
        let process_id = posAction?.process_id ?? state.activeProcess.id
        dispatch("call_pos_api", {
            method: "get",
            url: posAction?.param ?? "/pos/" + process_id + "/history",
            noProcessRefresh: true,
            successHandler: function({commit}, data) {
                commit("processHistory", data);
            }
        })
    },
    getProcesses({commit}) {
        axios.get(restUrlPosService + "/pos/current")
            .then(function (result) {
                if (result.status === 200) {
                    commit("setProcessList", {processListMode: "current", processList: result.data});
                } else {
                    commit("general/addToast", {
                        detail: result.statusText,
                        life: 3000,
                        severity: "error",
                        summary: "Error"
                    }, {root: true})
                }
            })
            .catch(function (error) {
                console.log(error)
                commit("general/addToast", {
                    detail: error.message,
                    life: 3000,
                    severity: "error",
                    summary: "Error"
                }, {root: true})
            });
    },
    getOpenProcesses({commit}) {
        axios.get(restUrlPosService + "/pos/open")
            .then(function (result) {
                if (result.status === 200) {
                    commit("setProcessList", {processListMode: "open", processList: result.data});
                } else {
                    commit("general/addToast", {
                        detail: result.statusText,
                        life: 3000,
                        severity: "error",
                        summary: "Error"
                    }, {root: true})
                }
            })
            .catch(function (error) {
                console.log(error)
                commit("general/addToast", {
                    detail: error.message,
                    life: 3000,
                    severity: "error",
                    summary: "Error"
                }, {root: true})
            });
    },
    // eslint-disable-next-line no-unused-vars
    payProcess({dispatch, commit}, {paymentInfo, successCallback}) {
        dispatch("call_pos_api", {
            method: "post",
            url: "/pos/" + paymentInfo.process_id + "/pay_process",
            noProcessRefresh: true,
            compressed: false,
            data: paymentInfo,
            successHandler: (state, data) => {
                if( successCallback ) {
                    successCallback(data);
                }
            }
        })
    },
    splitProcess({dispatch}, {splitInfo, successCallback}) {
        dispatch("call_pos_api", {
            method: "post",
            url: "/pos/" + splitInfo.process_id + "/split_process",
            data: splitInfo,
            successHandler: function({commit, dispatch}, data) {
                dispatch("getOpenProcesses");
                commit("setActiveProcess", data);
                if( successCallback ) {
                    successCallback();
                }
            }
        })
    },
    showProcessSignatures({dispatch}, {process}) {
        dispatch("call_pos_api", {
            method: "get",
            url: "/pos/" + process.id + "/tse_signature",
            noProcessRefresh: true,
            compressed: false,
            successHandler: ({commit}, result) => {
                commit("setTseInfo", result);
            }
        });
    },
    /**
     * Get the terminals from the posservice
     */
    getTerminals({commit}) {
        commit("general/addToast", {
            detail: "Retrieving terminals",
            life: 3000,
            severity: "info",
            summary: "Info"
        }, {root: true})
        // axios.get(restUrlPosService + "/terminals")
        //     .then(function (result) {
        //         commit("setTerminals", result.data.data)
        //     })
        //     .catch(function (error) {
        //         commit("general/addToast", {
        //             detail: error.message,
        //             life: 3000,
        //             severity: "error",
        //             summary: "Error"
        //         }, {root: true})
        //     });
    },

    /**
     * Set a new terminal
     */
    setActiveTerminal({commit}, terminal) {
        axios.put(restUrlPosService + "/sessions/set_terminal", {"terminal_id": terminal.terminal_id})
            .then(function (result) {
                if (result.data.errorcode === 0) {
                    commit("setActiveTerminal", result.data.data.pos_workstation.workstation_id)
                    commit("general/addToast", {
                        detail: "Set New Terminal Successful",
                        life: 3000,
                        severity: "success",
                        summary: "Success"
                    }, {root: true})
                } else {
                    commit("general/addToast", {
                        detail: result.data.errortext,
                        life: 3000,
                        severity: "error",
                        summary: "Error"
                    }, {root: true})
                }
            })
            .catch(function (error) {
                commit("general/addToast", {
                    detail: error.message,
                    life: 3000,
                    severity: "error",
                    summary: "Error"
                }, {root: true})
            });
    }
}

// mutations
const mutations = {
    appendToKeyboardDisplay (state, {keyboardDisplayId, value}){
        if (!state.keyboardDisplay[keyboardDisplayId]){
            state.keyboardDisplay[keyboardDisplayId] = ""
        }
        state.keyboardDisplay[keyboardDisplayId] += value
    },
    setKeyboardDisplay (state, {keyboardDisplayId, value}){
        state.keyboardDisplay[keyboardDisplayId] = value
    },
    setActiveProductCategory (state, {categoryId, productCategoryFilterId}) {
        state.activeProductCategory[productCategoryFilterId] = categoryId
    },
    refreshActiveProcess(state, process){
        state.activeProcess = process;
        if( process ) {
            state.processList[process.id] = process;
        }},
    setActiveProcess(state, process){
        state.activeProcess = process;
        state.keyboardDisplay["pos-main-keyboard-display"] = "";
        state.quantity = 1;
        if( process ) {
            state.processList[process.id] = process;

            if( process.state !== "closed" ) {
                const cashPaymentStore = useCashPaymentStore();
                cashPaymentStore.setPriceToPay(process.total);
            }
        }
    },
    setActiveProcessById(state, processId){
        state.activeProcess = state.processList[processId] || null;
        state.keyboardDisplay["pos-main-keyboard-display"] = "";
        state.quantity = 1;
    },
    setActiveTerminal (state, terminal) {
        state.activeTerminal = terminal
    },
    setProcess(state, process){
        if( process ) {
            state.processList[process.id] = process
        }
    },
    /**
     * Set a list of processes available
     * @param state
     * @param processList
     */
    setProcessList(state, {processListMode, processList}){
        state.processListMode = processListMode;
        state.processList = {};
        for (let process of processList){
            if( process.state !== "closed" || processListMode !== "open") {
                process.process_id = process.id
                state.processList[process.id] = process
            }
        }
    },
    processEntryCreated (state, {processId, item}) {
        if( state.processList[processId] ) {
            state.processList[processId].process_entries?.push(item)
        }
    },
    processEntryUpdated(state, {processId, item}) {
        if( state.processList[processId] ) {
            const filtered_item = state.processList[processId].process_entries.find(entry => entry.id === item.id)
            if (filtered_item) {
                Object.assign(filtered_item, item);
            } else {
                state.processList[processId].process_entries.push(item);
            }
       }
    },
    processDeleted(state, {processId}) {
        if( state.processList[processId] ) {
            delete state.processList[processId]
        }
    },

    /**
     * Set the list of product categories available to the pos
     * @param productCategories List of product categories
     */
    setProductCategories(state, productCategories){
        state.productCategories = productCategories
    },
    /**
     * Set the list of products available to the pos
     * @param products List of products
     */
    setProducts(state, products){
        state.products = products
    },
    setQuantity(state, qty){
        state.quantity = qty
    },
    setTerminals(state, terminals){
        state.terminals = terminals
    },
    setNavigations(state, navigations) {
        state.navigations = navigations;
    },
    setCompressed(state, compressed) {
        state.compressed = compressed;
    },
    setVoidAction(state, voidAction) {
        state.voidAction = voidAction;
    },
    setPosProcessDetailsExpanded(state, flag) {
        state.processDetailsExpanded = flag;
    },
    setProcessComboAction(state, comboAction) {
        if( comboAction ) {
            let updatedCombo = synchCombo(comboAction.rootCombo, null, 1, false);
            comboAction.rootCombo = updatedCombo
        }
        state.processComboAction = comboAction;
    },
    processComboSelected(state, {selectedEntry, added}) {
        let updatedCombo = synchCombo(state.processComboAction.rootCombo, selectedEntry, 1, added);
        state.processComboAction.rootCombo = updatedCombo;
    },
    productForInfo(state, product) {
        state.productForInfo = product;
    },
    processHistory(state, processHistory) {
        state.processHistory = processHistory;
    },
    setPaymentAction(state, paymentAction) {
        if( paymentAction !== undefined ) {
            const splitStore = useSplitEntriesStore();
            splitStore.init("Gesamt", "Split ", paymentAction.process);
        }

        state.paymentAction = paymentAction;
    },
    setSplitAction(state, splitAction) {
        if( splitAction !== undefined ) {
            const splitStore = useSplitEntriesStore();
            splitStore.init("Vorgang " + splitAction.process.name, "Vorgang ", splitAction.process);
        }
        state.splitAction = splitAction;
    },
    setTseInfo(state, tseInfo) {
        state.tseInfo = tseInfo;
    },
    setPaymentsInfo(state, paymentsInfo) {
        state.paymentsInfo = paymentsInfo;
    },
    setSelectProcessVisibleId(state, id) {
        state.selectProcessVisibleId = id;
    },
    setChangeTableVisible(state, flag) {
        state.changeTableVisible = flag;
    },
    setPosInstallationMode(state, posInstallationMode) {
        state.posInstallationMode = posInstallationMode;
    },
    setLayoutLeftRightHandMode(state, mode) {
        if( mode === "left" || mode === "right" ) {
            state.layoutLeftRightHandMode = mode;
        }
    },
    toggleLayoutLeftRightHandMode(state) {
        if( state.layoutLeftRightHandMode === "right" ) {
            state.layoutLeftRightHandMode = "left";
        } else {
            state.layoutLeftRightHandMode = "right";
        }
    },
    setLayoutFunctionalAreaMode(state, mode) {
        if( mode === "simple" || mode === "standard" || mode === "inner" || mode === "outer" ) {
            state.layoutFunctionalAreaMode = mode;
        }
    },
    toggleLayoutFunctionalAreaMode(state) {
        if( state.layoutFunctionalAreaMode === "simple" ) {
            state.layoutFunctionalAreaMode = "inner";
        } else if( state.layoutFunctionalAreaMode === "inner"  ) {
            state.layoutFunctionalAreaMode = "outer";
        } else if( state.layoutFunctionalAreaMode === "outer" ) {
            state.layoutFunctionalAreaMode = "standard";
        } else {
            state.layoutFunctionalAreaMode = "simple";

        }
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    getDefaultState
}
